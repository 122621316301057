import React, {useState} from 'react'  
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'
import Moment from "react-moment"  
import { DayPicker, SelectSingleEventHandler } from 'react-day-picker';
import { ru } from 'date-fns/locale';
import 'react-day-picker/dist/style.css'; 
import "moment/locale/ru"

const DateTime = ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
        { ...props }
        enabledForm={<DateTimeEnabled {...props} />}
        desabledForm={<DateTimeDesabled {...props} />}
    />
} 

export default DateTime
 
export const DateTimeEnabled = ( props: IScalarForm ): JSX.Element => {
    const [value, setValue] = useState<Date>( new Date(props.value) )  
    const onChange = (data: Date, data2: Date, data3: any) => {
        setValue( data )
        console.log( data.getTime(), data )
        if(props.onChange && data) props.onChange( Math.floor( data.getTime()/ 1000 ), props.field, "" )
    }
    const css = `
        .my-selected:not([disabled]) { 
            font-weight: bold; 
            border: 2px solid currentColor;
        }
        .my-selected:hover:not([disabled]) { 
            border-color: blue;
            color: blue;
        }
        .my-today { 
            font-weight: bold; 
            color: red;
        }
        `;
    return (
        <div>
            <style>{css}</style>
            <DayPicker
                mode="single"
                selected={ value }
                numberOfMonths={1} 
                onSelect={ onChange as SelectSingleEventHandler}
                locale={ ru }
                showOutsideDays
                fixedWeeks 
                modifiersClassNames={{
                  selected: 'my-selected',
                  today: 'my-today'
                }}
                modifiersStyles={{
                  disabled: { fontSize: '75%' }
                }}
            />
            <Moment locale="ru" format="D MMMM YYYY">
                {new Date( value )}
            </Moment>
        </div>
    )
}  

export const DateTimeDesabled = ( props: IScalarForm ): JSX.Element => {
    return <div className="d-flex my-1">
        <i className="fas fa-calendar-alt pr-1" />
        <div className="">
            <Moment locale="ru" format="D MMMM YYYY">
                {new Date(props.value)}
            </Moment>
        </div>
    </div>
}  