import { __ } from "@/libs/utilities"
import { Button, Collapse, Icon, Intent, Position, Tooltip } from "@blueprintjs/core" 
import { DownloadTableExcel } from "react-export-table-to-excel"
import FieldInput from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import RussionRegionList from "../../data/RussianRegionList"
import SpecialFieldsEngine from "./SpecialFieldsEngine"
import { FILTER_NAME, IPEFestSchool, ITrack, SORT_ORDER_ASC, SPECIAL_SORT_TYPE } from "../../data/interfaces"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { MutableRefObject, useState } from "react"
import { ID } from "@/libs/interfaces/layouts"

/* Protopia Ecosystem component */
export interface ISpecialPanelProps {
    memberTRs: JSX.Element[] | JSX.Element
    fields: string[]
    onSomeFilter: (value: (ID | string)[] | number , field: FILTER_NAME ) => void
    onSelected: (  _selected: string[] ) => void
    onSortOrder: () => void
    setSortField : (sortField: SPECIAL_SORT_TYPE) => void
    schoolFilter: any
    trackFilter: any
    regionFilter: any
    ratingMaxFilter: any
    selected: string[]
    tableRef: MutableRefObject<null>
    regionId: number
    sortField: SPECIAL_SORT_TYPE
    sortOrder: string
} 
const SpecialPanel = (
    {
        memberTRs, 
        fields,
        onSomeFilter,
        onSelected,
        schoolFilter,
        trackFilter,
        regionFilter,
        ratingMaxFilter,
        selected,
        tableRef,
        onSortOrder,
        regionId,
        sortField,
        setSortField,
        sortOrder,
        ...props
    }: ISpecialPanelProps
): JSX.Element => {
    const tracks: ITrack[] = useDataStore((state:any) => state.PEFestTrack || [])
    const schools: IPEFestSchool[] = useDataStore((state:any) => state.PEFestSchool || []) 

    const [ isSchoolOpen,  setSchoolOpen  ] = useState( false ) 
    const [ isTrackOpen,  setTrackOpen  ]   = useState( false ) 
    const [ isRegionOpen, setRegionOpen ]   = useState( false )
    const [ isFieldOpen,  setFieldsOpen ]   = useState( false ) 
    const [ isRatingMaxOpen,  setRatingMaxOpen ]   = useState( false ) 

    const printIt = () =>
    {
        const restorepage = $('body').html(); 
        $( "fest-header" ).hide()
        $( "fest-phase-labels" ).hide()
        $( "fest-head-switcher" ).hide()
        const printPage = $('body').html()
        $('body').empty().html( printPage )
        window.print();
        $('body').html(restorepage); 
    }  
    return <div className='mt-0 mb-0 w-100'>
        <div className='align-items-center w-100 mb-1 d-flex justify-content-md-between justify-content-center flex-md-row flex-column'>
            <div className='d-flex flex-md-row flex-column'>

                <div className='flex-centered border-secondary-medium border2 '>
                    <div className='flex-centered descr text-uppercase text-secondary mx-2'>
                        {__("Filters")}:
                    </div>
                    <Button intent={Intent.SUCCESS} minimal={ !isTrackOpen } onClick={() => setTrackOpen( !isTrackOpen ) }>
                        <span className="small">
                            {__("Tracks")} | { trackFilter.length }
                        </span>
                    </Button>
                    <Button intent={Intent.SUCCESS} minimal={ !isSchoolOpen } onClick={() => setSchoolOpen( !isSchoolOpen ) }>
                        <span className="small">
                            {__("Schools")} | { schoolFilter.length }
                        </span>
                    </Button>
                    { 
                        regionId >= 0 &&
                        <Button intent={Intent.SUCCESS} minimal={ !isRegionOpen } onClick={() => setRegionOpen( !isRegionOpen ) }>
                            <span className="small">
                                {__("Regions")} | { regionFilter.length }
                            </span>
                        </Button> 
                    }
                    <Button intent={Intent.SUCCESS} minimal={ !isRatingMaxOpen } onClick={() => setRatingMaxOpen( !isRatingMaxOpen ) }>
                        <span className="small">
                            {__("By rating")} | { ratingMaxFilter }
                        </span>
                    </Button> 
                </div>

                <div className=' flex-centered border-secondary-medium border2 ml-1 '>
                    <span className="small mx-2 descr text-uppercase text-secondary">
                        {__("Do sort")}:
                    </span>
                    <Button 
                        intent={Intent.SUCCESS} 
                        minimal={sortField !== SPECIAL_SORT_TYPE.ORDER_SORT}
                        onClick={() => setSortField(SPECIAL_SORT_TYPE.ORDER_SORT)}
                    >
                        <span className="small">
                            {__("By Member's order")}
                        </span>
                    </Button>
                    <Button 
                        intent={Intent.SUCCESS} 
                        minimal={sortField !== SPECIAL_SORT_TYPE.ALPHABET_SORT}
                        onClick={() => setSortField(SPECIAL_SORT_TYPE.ALPHABET_SORT)}
                    >
                        <span className="small">
                            {__("By alphabet")}
                        </span>
                    </Button>
                    <Button 
                        intent={Intent.SUCCESS} 
                        minimal={sortField !== SPECIAL_SORT_TYPE.RATING_SORT}  
                        onClick={() => setSortField(SPECIAL_SORT_TYPE.RATING_SORT)}
                    >
                        <span className="small">
                            {__("By rating")}
                        </span>
                    </Button> 
                    <Button 
                        intent={ Intent.SUCCESS } 
                        minimal={ sortField !== SPECIAL_SORT_TYPE.SCHOOLS_SORT }  
                        onClick={ () => setSortField(SPECIAL_SORT_TYPE.SCHOOLS_SORT) }
                    >
                        <span className="small">
                            {__("By schools")}
                        </span>
                    </Button> 
                    <Button 
                        intent={ Intent.SUCCESS } 
                        minimal={ sortField !== SPECIAL_SORT_TYPE.TRACK_SORT }  
                        onClick={ () => setSortField(SPECIAL_SORT_TYPE.TRACK_SORT) }
                    >
                        <span className="small">
                            {__("By track")}
                        </span>
                    </Button> 
                    <Button 
                        intent={Intent.SUCCESS} 
                        minimal  
                        onClick={ onSortOrder }
                    >
                        <span className="small">                            
                            <Icon 
                                icon={ sortOrder === SORT_ORDER_ASC 
                                    ?  
                                    sortField === SPECIAL_SORT_TYPE.RATING_SORT
                                        ?
                                        "sort-numerical-desc"
                                        :
                                        "sort-asc" 
                                    : 
                                    sortField === SPECIAL_SORT_TYPE.RATING_SORT
                                        ?
                                        "sort-numerical"
                                        :
                                        "sort-desc"
                                } 
                                size={20} 
                            />
                        </span>
                    </Button>
                </div>

                <div className='flex-centered  border-secondary-medium border2 ml-1'>
                    <Button intent={Intent.SUCCESS} minimal={!isFieldOpen} className=' px-3 py-2 mx-0' onClick={() => setFieldsOpen(!isFieldOpen)}>
                        <span className="small">
                            {__("Fields")}
                        </span>
                    </Button>
                </div>
            </div>

            <div className='flex-centered'>
                <Tooltip
                    content={__("Projects count")}
                    position={Position.TOP_RIGHT}
                >
                    <div className=' lead untouchble'>{ Array.isArray(memberTRs) ? memberTRs.length : "" }</div>
                </Tooltip>  
                <Tooltip
                    content={__("Print")}
                    position={Position.TOP_RIGHT}
                >
                    <Button minimal className='px-3 py-2 ml-3' onClick={printIt}>
                        <Icon icon="print" size={15} />
                    </Button>
                </Tooltip> 
                <DownloadTableExcel
                    filename="projects"
                    sheet="projects"
                    currentTableRef={ tableRef.current }
                >
                    <Tooltip
                        content={__("Download XLS")}
                        position={Position.TOP_RIGHT}
                    >
                        <Button minimal className='px-3 py-2 '>
                            <Icon 
                                icon="th" 
                                size={15} 
                            />
                        </Button> 
                    </Tooltip>                 
                </DownloadTableExcel> 
            </div> 
        </div>
        <Collapse isOpen={ isSchoolOpen } className=" light-colored ">
            <div className="px-3">
                <FieldInput
                    type="tags"
                    multiple
                    vertical={VerticalType.VERTICAL}
                    editable
                    values={
                        schools.map(school => {
                            return { _id: school.id, title: school.title, intent: Intent.WARNING }
                        })
                    }
                    value={schoolFilter}
                    onChange={(ids: ID[]) => onSomeFilter(ids, FILTER_NAME.SCHOOL) } 
                />          
            </div> 
        </Collapse>
        <Collapse isOpen={ isTrackOpen} className=" light-colored ">
            <div className="px-3">
                <FieldInput
                    type="tags"
                    multiple
                    vertical={VerticalType.VERTICAL}
                    editable
                    values={
                        tracks.map(track => {
                            return { _id: track.id, title: track.title, intent: "success" }
                        })
                    }
                    value={trackFilter}
                    onChange={(ids: ID[]) => onSomeFilter(ids, FILTER_NAME.TRACK)} 
                />        
            </div> 
        </Collapse>
        <Collapse isOpen={ isRegionOpen} className=" light-colored ">
            <div className="px-3">
                <FieldInput
                    type="tags" 
                    className={` w-100 input dark form-control `}
                    multiple
                    vertical={VerticalType.VERTICAL}
                    values={ RussionRegionList() }
                    editable 
                    value={regionFilter}
                    onChange={ (ids: ID[]) => onSomeFilter(ids, FILTER_NAME.REGION) } 
                />        
            </div> 
        </Collapse>
        <Collapse isOpen={ isRatingMaxOpen } className=" light-colored ">
            <FieldInput 
                type="slider"  
                title={__("Only more than")}
                commentary={__("Filter all member that rating low this parameter")}
                className="max-width-100 ml-5"
                min={0}
                max={100}
                stepSize={1}
                labelStepSize={5} 
                editable 
                value={ratingMaxFilter}
                onChange={(max: number) => onSomeFilter(max, FILTER_NAME.RATING_MAX) } 
            />
        </Collapse>
        <Collapse isOpen={ isFieldOpen} className=" light-colored ">
            <div className='p-3'>
                <SpecialFieldsEngine
                    selected={selected}
                    setSelected={onSelected}
                    fields={fields}
                />
            </div>
        </Collapse>
    </div>
}
export default SpecialPanel