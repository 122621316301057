import ConfirmButton from "@/libs/useful/ConfirmButton"
import { IFieldInputGroupProps } from "./FieldInputGroup"
import { useState } from "react"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { __ } from "@/libs/utilities"
import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"

/* Protopia Ecosystem component */ 
const UserExtended = (props: IFieldInputGroupProps): JSX.Element => {
    const [ newPassword, setNewPassword ] = useState<string>("")
    const [ newPassword2, setNewPassword2 ] = useState<string>("")
    const [ compared, setCompared ] = useState<boolean>(true)
    const onConfirm = () => {
        actions(
            UPDATE_SINGLE_TYPE, 
            { 
                data_type: "User", 
                id: props.item.id, 
                item: {
                    password: newPassword
                }, 
                landId: props.landId
            }
        )
        .then( resp => console.log( resp ) )

    }
    const onSetPassword = (value: string) => {
        setNewPassword( value )
        setCompared( value === newPassword2 )
    }
    const onSetPassword2 = (value: string) => {
        setNewPassword2( value )
        setCompared( value === newPassword )
    }
    return props.data_type === "User"
        ?
        <div className='container p-3'>
            <div className="row">
                <div className="col-md-9 offset-md-3">
                    <ConfirmButton
                        onConfirm={ onConfirm }
                        buttonLabel={ __( "Set new password" ) } 
                        yesLabel={ __( "Set new password just now" ) }
                        noLabel={ __( "Cancel" ) }
                        yesLock={ !compared || !newPassword }
                        dialogAddedClassName={ DIALOG_SIZE.MINIMAL }
                    >
                        <div className="p-4">
                            <FieldInput
                                vertical={VerticalType.VERTICAL }
                                type={SCALAR_TYPES.PASSWORD }
                                editable
                                value={newPassword}
                                title={ __( "New password" ) }
                                onChange={ onSetPassword }
                                className={
                                    compared
                                        ?
                                        "my-3 form-control input dark flex-grow-100"
                                        :
                                        "my-3 form-control input dark flex-grow-100 danger"
                                }
                            />
                            <FieldInput
                                vertical={ VerticalType.VERTICAL }
                                type={ SCALAR_TYPES.PASSWORD }
                                editable
                                value={ newPassword2 }
                                title={ __( "Repeat new password" ) }
                                onChange={ onSetPassword2 }
                                className={
                                    compared
                                        ?
                                        "my-3 form-control input dark flex-grow-100"
                                        :
                                        "my-3 form-control input dark flex-grow-100 danger"
                                }
                            />
                        </div>
                    </ConfirmButton>
                </div>
            </div>
            
        </div>
        :
        <></>
}
export default UserExtended