import React from 'react' 
import { Tag } from '@blueprintjs/core'
import {__} from 'src/libs/utilities'
import { ID } from 'src/libs/interfaces/layouts'
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'

const Checkbox = ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<CheckboxEnabled {...props} />}
        desabledForm={<CheckboxDesabled {...props} />}
    /> 
}  
export default Checkbox

const CheckboxEnabled = ( props: IScalarForm ): JSX.Element => {
    const onChange = (elem:ID ) => { 
        let currentValue = Array.isArray(props.value) ? [ ...props.value ] : []
        const isEnabled: boolean = currentValue.filter(val => {
            return elem === ( val._id ? val._id : val ) 
        }).length > 0 
        if(isEnabled) {
            currentValue = currentValue.filter(v => v !== elem)
        }
        else {
            currentValue = [...currentValue, elem]
        }
        if(props.onChange) props.onChange( currentValue, props.field, "" )
    }  
    return <div> 
        {
            (props.values || [])?.map((value, i) => {
                const elem = typeof value === "string" ? { _id: value, title: value } : value
                //console.log( elem );
                const sel = Array.isArray( props.value ) 
                    ?                    
                    !!props.value?.filter(( el: any ) => el === elem._id).length
                    :
                    props.value?.includes(value)
                return <div className="pb-0 mb-1" key={i}>
                    <label className="_check_">
                        <input
                            value={elem._id}
                            type="checkbox"
                            checked={sel}
                            onClick={ () => onChange(elem._id) }
                            onChange={ () => onChange(elem._id) }
                        />
                        <div>{ __( elem.title ) } </div> 
                        <div>{ __( elem.description ) } </div>
                    </label>
                </div>
            })
        }
    </div>
}
const CheckboxDesabled = ( props: IScalarForm ): JSX.Element | JSX.Element[] => {
    console.log( props )
    return Array.isArray(props.value)
        ?
        <div className="flex-wrap d-flex">
            {
                props.value.map( ( value, i ) => {
                    const v = value 
                    return <Tag key={ i } round className='mr-1 mb-1 px-2' minimal>
                        <div className="small">
                            {i}: { __(v) }
                        </div>
                    </Tag>
                })
            }
        </div>
       
        :
        <Tag minimal round className='m-1' >
            { props.value  ? props.value  : ""}
        </Tag>
}