import { IFestStore, useFestStore } from "../../data/store"
import GanreTax from "./filter-terms/GanreTax"
import HoneyombsTax from "./filter-terms/HoneyombsTax"
import SchoolTax from "./filter-terms/SchoolTax"
import TrackTax from "./filter-terms/TrackTax"

/* Protopia Ecosystem component */
export interface IPEFestProjectTermProps {
   
}
const PEFestProjectTerm = (props: IPEFestProjectTermProps): JSX.Element => {
    
    const filters: any = useFestStore((state: IFestStore) => state.filters)

    return <div className='pe-pe-fest-project-term-container'> 
        <TrackTax filter={filters.tracks} />
        <SchoolTax filter={filters.schools} />
        <HoneyombsTax filter={filters.honeycombs} />
        <GanreTax filter={filters.ganres} />
    </div>
}
export default PEFestProjectTerm