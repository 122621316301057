import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */
export interface ITrackClosedLabelProps {
   is_closed: boolean 
}
const TrackClosedLabel = ({is_closed}: ITrackClosedLabelProps): JSX.Element => {
    return is_closed
        ?
        <span 
            style={{
            position:"absolute",
            top: 80,
            left: 50,
            width:"120%",
            transform: "rotate(15deg)",
            marginLeft: -90, 
            height: 40, 
            display:"flex", 
            alignItems:"center", 
            justifyContent:"center", 
            padding:"2px 12px", 
            backgroundColor:"red",
            whiteSpace : "nowrap",
            fontSize: 22,
            textTransform:"uppercase",
            color:"#FFF"
            }}
        >
            { __( "Register closed!" ) }
        </span>
        :
        <></>
}
export default TrackClosedLabel