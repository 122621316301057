import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { ID, ITax } from "@/libs/interfaces/layouts"
import { singleName } from "@/libs/layouts"
import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { __ } from "@/libs/utilities"
import { useParams } from "react-router"

/* Protopia Ecosystem component */
export interface IEditProjectTaxProps {
    isEditable: boolean
    isRequired: boolean 
    data_type: string
    item?: ID 
    items?: ID[]
    onChange: ( item: ITax | ITax[] ) => void
    isMulti?: boolean
    isThumbnail?: boolean
    commentary?: string
}
const EditProjectTax = ({ 
    isRequired, 
    data_type,
    item, 
    isEditable,
    ...props
}: IEditProjectTaxProps): JSX.Element => {
    const {landId} = useParams()
    const title: string = singleName( data_type )
    const terms : ITax[] = useDataStore((state: any) => state[data_type]) || [] 
    return Array.isArray( terms) &&  terms.length > 0 
        ? 
        <div className="row data-list p-0">
            <div className="col-md-4 col-sm-4 my-auto">
                <div className='d-flex justify-content-between align-items-center w-100'>
                    {
                        isRequired && <div className='require-sign mr-4 h-100'>
                            <div className='d-flex user-select-none descr text-danger'>
                                {__("Required field")}
                            </div>  
                        </div>
                    }
                    <div className="text-right w-100">
                        <div className={`title ${isRequired ? "text-danger" : ""}`}>
                            {__( title )}
                        </div> 
                    </div>                      
                </div> 
            </div> 
            <div className="col-md-8 col-sm-8 p-0">
                <FieldInput 
                    type={ 
                        props.isMulti 
                            ? 
                            props.isThumbnail ? SCALAR_TYPES.IMAGE_CHECKBOX: SCALAR_TYPES.EXTERNAL_TAGS 
                            : 
                            props.isThumbnail ? SCALAR_TYPES.IMAGE_RADIO: SCALAR_TYPES.EXTERNAL  
                    }
                    isMulti={props.isMulti}
                    component={ data_type}
                    landId={landId} 
                    className={ ` w-100 d-flex justify-content-center mb-0` }
                    vertical={ VerticalType.VERTICAL }
                    editable={ isEditable } 
                    value={ terms?.filter(t => t.id === item)[0] }  
                    values={terms.map(t => ({
                        _id: t.id, 
                        title: t.title,
                        img: t.thumbnail,
                        height: 50
                    })) }
                    onChange={ id => {
                        props.onChange( id )
                    }}
                /> 
                {
                    props.commentary &&
                    <div className="descr pb-3">
                        {__( props.commentary )}
                    </div>
                }
            </div>
        </div>
        :
        <></> 
}
export default EditProjectTax