

import LogsForm from "src/modules/pe-admin-module/views/logs/LogsForm";
import MediaListForm from "src/modules/pe-admin-module/views/media-list/MediaListForm";
import { useFestDataStore } from "src/modules/pe-fest-module/data/store";
import EventTypeForm from "src/modules/pe-fest-module/views/festAdmin/EventTypeForm";
import CommunicationsForm from "../../views/admin/CommunicationsForm";
import InheritanceForm from "../../views/admin/InheritanceForm";
import { useToposStore } from "../store";
import DateSelect from "../../views/admin/DateSelect";


export const getAdminRoutes: any = ( ) => {    
    const eventTypes  = useFestDataStore.getState().site.event_types
    const eventTypeAdminData: any = useToposStore.getState().eventTypeAdminData
    let addedData: any = {}
    Object.keys(eventTypeAdminData)
        .filter( key => { 
            return eventTypes?.includes(key)
        })
        .forEach( key => { 
            if(!!eventTypeAdminData[key]) {
                addedData = {...addedData, ...eventTypeAdminData[key]}
            }
        })  
    const data: any =  {
        eventTypes : {
            title: "Events setting",
            group: "Events setting",
            component: EventTypeForm,
            position: "left", 
            icon: <i className="fas fa-calendar-alt"></i>, 
            rightMenuWidget:  <div className="d-flex" ><DateSelect field="startDate" /><DateSelect  field="finishDate"/></div> 
        },
        communications : {
            title: "Communications",
            group: "Events setting",
            component: CommunicationsForm,
            position: "left"
        },
        partners :  {
            title: "Partners",
            data_type: "PEToposPartner",
            group: "Events setting",
            position: "left", 
        }, 
        abbyss : {
            title: "Abyss utilities",
            group: "Events setting",
            component: InheritanceForm,
            position: "right"
        },
        media : {
            title: "Media library",
            group: "Events setting", 
            component: MediaListForm,
            position: "right"
        },
        logs : {
            title: "Logs",
            group: "Events setting", 
            component: LogsForm,
            position: "right"
        },
        messages : {
            title: "User messages", 
            group: "Events setting", 
            undergroup: "Messages",
            data_type: "PEMessage",
            position: "left", 
            icon: <i className="fas fa-envelope"></i>, 
        },
        ads : {
            title: "Ads", 
            group: "Events setting", 
            undergroup: "Messages",
            data_type: "Ad",
            position: "left", 
            icon: <i className="fas fa-envelope"></i>, 
        },
        accounts :  {
            title: "Users accounts",
            data_type: "User",
            group: "Events setting",
            undergroup: "Users management",
            position: "left",
            icon: <i className="fas fa-user-friends"></i> 
        },
        start_date: {
            title: "Start date",
            group: "Events setting", 
            position: "head",
            icon: <i className="fas fa-clock"></i>, 
            component: DateSelect
        },
        finish_date: {
            title: "Finish date",
            group: "Events setting", 
            position: "head",
            icon: <i className="fas fa-clock"></i>, 
            component: DateSelect
        },
        // "auto-created-accounts" :  {
        //     title: "Autocreated Users",
        //     component: AutoCreatedUsersForm,
        //     group: "Events setting",
        //     undergroup: "Users management",
        //     position: "right"
        // },
        ...addedData
    } 
    return data
} 