
/* Protopia Ecosystem component */
export interface IHonecombsProps {
   
}
const Honecombs = (props: IHonecombsProps): JSX.Element => {
    return <div className='pe-honecombs-container'>
       
    </div>
}
export default Honecombs