
import React from "react";
import { create } from "zustand";
import { persist, createJSONStorage, devtools } from 'zustand/middleware'
import { ID, IDictionary, ILayoutData, URL } from "src/libs/interfaces/layouts";  
import { LayoutDummy } from "@/libs/interfaces/dummies"; 


export interface IApolloState { 
    client: any
    setClient : () => any, 
    getClient: () => any
}
export interface ITheme {
    name: THEME_NAMES 
}
export interface IUser {
    id: ID
    display_name?: string
    avatar?: URL
    roles: string[]
    external?: string
    externalId?: string
    post_content?: string
    email?: string
    [partName: string]: any
}

export enum THEME_NAMES {
    DAY     = 'day',
    NIGHT   = 'night'
}
export interface IState {
    user: IUser
    setUser : (user:IUser) => void  
    layout: ILayoutData
    config: any
    theme: THEME_NAMES
    location: string
    landId: ID 
    event_types: string[]
    setTheme: ( theme: THEME_NAMES ) => void
    pageCount: number
    setPageCount: ( pageCount: number ) => void
    current: number
    isGlleryFullSize: boolean
    isGalleryOpenPics: boolean
    dictionary: IDictionary,
    dictionary_version: number
    isOpenLogin: boolean
}
export const useMainStore: any = create( devtools( persist<IState>( (set: any, get: any ) => ({
        user: { } as IUser,
        setUser: (user:IUser) => set( () => ({ user : user || {} }) ), 
        layout: LayoutDummy(),
        config: {},
        theme: THEME_NAMES.DAY,
        location: "",
        landId: -1,
        
        event_types: [],
        setTheme: (theme: THEME_NAMES) => set(() => ({ theme })),
        pageCount : 33,
        setPageCount: ( pageCount: number ) => set( () => ({ pageCount }) ),
        current: -1,
        isGlleryFullSize: false,
        isGalleryOpenPics: false,
        dictionary: {},
        dictionary_version: 0,
        isOpenLogin: false
    }),
    {
        name: 'pe-main-storage', 
        storage: createJSONStorage(() => localStorage), 
    } 
)))
export const useAppoloStore: any = create ( devtools((set: any, get: any ) => ({
        client: { },
        setClient: ( client: any ) => set( ( ) => ({ client }) ),
        getClient : () => get().client
    })
) ) 
 

export const withStore = ( ChildComponent:any  ) => (props: object )  => {
    const storeObj:any = useMainStore(); 
    return <ChildComponent {...props} store={storeObj} /> 
};

export interface ILayoutStore {
    headerHeight: number
    footerHeight: number
    fixMobileTop: boolean,
    title: string,
    description: string,
    icon: URL,
    isLayoutLoading: boolean,
}
export const useLayoutStore : any = create( 
    devtools<ILayoutStore>(  
        (set: any, get: any ) => ({
            headerHeight: 0,
            footerHeight: 0,
            fixMobileTop: false,
            isLayoutLoading: false,
            title: "",
            description: "",
            icon: "",
        }) 
    ) 
)
 