import { Button, Intent } from "@blueprintjs/core"
import Moment from "react-moment"
import { IAds } from "../../data/interfaces"
import { __ } from "src/libs/utilities"
import { IFeedProps } from "@/modules/pe-basic-module/views/core/FeedComponent"
import { getURL } from "src/libs/scalars/scalars/URL"

interface IProps extends IFeedProps { 
    item: IAds
    className?: string
}
const AdCard = ({item, className, ...props }:IProps) :JSX.Element => {
    let colClass = " col-12 " 
    if( props.params?.col )
    {
        switch(parseInt( props.params?.col ))
        {
            case 2:
                colClass = " col-md-6 "
                break;
            case 3:
                colClass = " col-md-4 "
                break;
            case 4:
                colClass = " col-md-3 "
                break;
            case 1:
            default:                
                colClass = " col-12 "
                break;

        }
    }
    return <div className={ ` position-relative  flex-centered flex-column ${ className } ${colClass} my-3 ` }>
            <div className="d-flex border border-secondary " style={{maxWidth:700, minHeight: 270}}>
                <div className="thumbnail h-100 position-relative w-50" style={{ backgroundImage: `url(${ item.thumbnail })`, }}>
                    <img src="http://fakeimg.pl/150x200" alt="fake images please?" className="unvisibled h-100" style={{width: "auto"}}/> 
                    <Button minimal className="position-absolute m-1 bottom bg-dark text-light">
                        <div className="small">
                            { item.parent?.title}
                        </div>
                    </Button> 
                    {
                        !!item.date &&
                            <Moment  locale="ru" format="D.MM.YYYY" className={`px-3 py-1 bg-secondary position-absolute top left m-1`}>
                                { parseInt(item.date.toString()) * 1000 }    
                            </Moment> 
                    }
                </div>
                <div className="position-relative w-50"> 
                    <div className="bg-secondary-light p-3 h lead" style={{fontWeight: 900, lineHeight:0.9}}>
                        {item.title}
                    </div> 
                    <div className={`  m-3`}>
                       {item.post_content}    
                    </div>  
                    {
                        item.url &&
                        <a href={ getURL(item.url)[0]} target="_blank" rel="noreferrer" className="position-absolute m-1 bottom right">
                            <Button intent={Intent.SUCCESS} large>
                                { getURL(item.url)[1] || __("Ins")}
                            </Button>
                        </a> 
                    } 
                </div>
            </div>
        </div>
}

AdCard.propTypes = {}

export default AdCard