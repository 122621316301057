import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { AppToaster } from "@/libs/useful"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION, UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { ILand, ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, useFestDataStore } from "@/modules/pe-fest-module/data/store"
import { useIsTrackModerator } from "@/modules/pe-fest-module/hooks/track"
import { IAds } from "@/modules/pe-topos-module/data/interfaces"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface ITrackAdAddProps {
   track: ITrack
}

const TrackAdAdd = ({track}: ITrackAdAddProps): JSX.Element => {
    const land:ILand = useFestDataStore((state:IFestDataStor) => state.site ) 
    const isTrackModerator  = useIsTrackModerator( track )
    const [ad, setAd] = useState<IAds>({ parent_type: "PEFestTrack", parent_id: track?.id } as IAds)

    const onChange = (value: any, field: string) => {
        setAd({
            ...ad,
            [field]: value
        })
    }
    const onThumbnail = (value: any, name: string) => {
        console.log( name )
        setAd({
            ...ad,
            thumbnail: value,
            thumbnail_name: name
        })
    }
    const onAdd = () => {
        console.log( ad )
        const data = {
            data_type: "Ad",
            item: ad,
            landId: land.id
        }
        actions(UPDATE_SINGLE_TYPE, data)
            .then(resp => {
                AppToaster.show({
                    message: __("Attachment created.")
                })
                actions( GET_FEED_ACTION, { land_id: land.id, data_type: "Ad", } )
                actions( GET_FEED_ACTION, { land_id: land.id, data_type: "PEFestTrack", } )
            })
    }
    console.log( isTrackModerator )
    return isTrackModerator
        ?
        <div className='pe-trackadadd-container'>
            <ConfirmButton
                    buttonIcon="plus"
                    buttonMinimal
                    buttonIntent="danger"
                    onConfirm={ onAdd }
                    yesLabel={ __("Add new Ad") }
                    noLabel={  __("Close") }
                    dialogTitle={__("Add new Ad")}
            >
                <div className="p-4">
                    <FieldInput
                        title={ __( "Title" ) }
                        value={ ad.title }
                        onChange= {(value:string) => onChange(value, "title")}
                        editable
                    />
                    <FieldInput
                        title={ __( "Content" ) }
                        value={ ad.post_content }
                        type={SCALAR_TYPES.HTML}
                        onChange= {(value:string) => onChange(value, "post_content")}
                        editable
                    />
                    <FieldInput
                        title={ __( "Thumbnail" ) }
                        value={ ad.thumbnail }
                        onChange= {(value:string, field:string, file: any) => onThumbnail(value, file.name)}
                        type={SCALAR_TYPES.MEDIA}
                        editable
                    />
                    <FieldInput
                        title={ __( "Date" ) }
                        value={ ad.date }
                        onChange= { ( value:number ) => onChange( value, "date") }
                        type={ SCALAR_TYPES.DATE }
                        editable
                    />
                    <FieldInput
                        title={ __( "Link" ) }
                        value={ ad.url }
                        onChange= {(value:string) => onChange(value, "url")}
                        type={SCALAR_TYPES.URL}
                        editable
                    /> 
                </div>
            </ConfirmButton>
        </div>
        :
        <></>
}
export default TrackAdAdd