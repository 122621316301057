import React, { useEffect, useState } from 'react'

import { Icon, Tag as BTag } from '@blueprintjs/core'
import { __ } from 'src/libs/utilities'
import IScalarForm from '../interface/IScalarForm'
import { ID } from 'src/libs/interfaces/layouts'
import Scalar from './Scalar'


const Tags = ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<TagsEnabled {...props} />}
        desabledForm={<TagDesabled {...props} />}
    /> 
} 
const TagDesabled = (props: IScalarForm) : JSX.Element => {
    if(!Array.isArray(props.values)) return props.value.toString()
    const curr: any = props.values.filter((v:any) => v._id === props.value)[0]
    const v: string =  typeof curr === "undefined"
        ?
        props.value.toString()
        :
        curr.title
    return <BTag round className="px-4 py-1 m-1">{__(v)}</BTag>
}
const TagsEnabled = (props: IScalarForm) :JSX.Element => {
    // console.log( props )
    const [value, setValue] = useState( () => {
        if(props.multiple)
        {
            let v =  Array.isArray(props.value) ? [ ...props.value ] : [] 
            return v
        }
        else
        {
            return props.value
        } 
    })
    useEffect( () => {
        setValue( () => {
            if(props.multiple)
            {
                let v =  Array.isArray(props.value) 
                    ? 
                    [ 
                        ...props.value
                    ] 
                    : 
                    [] 
                return v
            }
            else
            {
                return props.value
            } 
        })
        // console.log( props.value )
    }, [ props.value ] ) 
    const onClick = (id : ID) =>
    {
        // console.log( "onClick", id )
        setValue(id)
        if( props.onChange )
            props.onChange(id,  props.field, "" )
    }
    const onMultipleClick = (id : ID) =>
    {
        // console.log( "onMultipleClick", value)
        let vv
        if(value.filter((v: ID) => v === id ).length > 0)
        {
            vv = value.filter((v: ID) => v !== id )
        }
        else
        {
            vv = [...value]
            vv.push(id)
        }
        // setValue( vv )        
        if( props.onChange )
            props.onChange(vv,  props.field, "")
    }
    const onAll = () =>
    {
        let vv = value.length === props.values?.length 
            ? 
            [] 
            : 
            [ ...(props.values ? props.values?.map( v => v._id ? v._id : v ) : [] ) ]
        // setValue( vv )
        if( props.onChange )
            props.onChange(vv,  props.field, "")
    }
    const values = Array.isArray([ ...(props.values ? props.values : [] ) ])
        ?
        props.values?.map( (v, i) =>
        {
            return !props.multiple
                ?
                single({ v, value, i, onClick })
                :
                miltiple({ v, value, i, onMultipleClick })
        })
        :
        [ ]
    return (
        <div> 
            <div className='w-100 d-flex flex-wrap py-2 '> 
                { values } 
                {
                    props.multiple
                        ?
                        <BTag 
                            intent={"primary"} 
                            className="m-1 px-3 py-1 pointer" 
                            minimal 
                            round
                            onClick={ onAll }
                        > 
                            { __( "All" ) }
                        </BTag>
                        :
                        null
                } 
            </div>
        </div>
        
    )
} 

export default Tags

const miltiple = (props: any) : JSX.Element | null =>
{
    const {v, value, i} = props
    // console.log( v, value, i )
    return  v?._id 
        ?
        <BTag 
            key={ v._id + "_" + i } 
            intent={ v.intent }
            className="m-1 px-3 py-1 pointer" 
            round
            minimal={ value.filter( (e: any) => e === v._id ).length === 0 }
            onClick={() => props.onMultipleClick( v._id )}
        >
            <div className='pl-3' dangerouslySetInnerHTML={{__html: v.title}} />
        </BTag>
        :
        typeof v === "string"
            ? 
            <BTag 
                key={v + "_" + i} 
                data-value={ v }  
                className="m-1 px-3 py-1 pointer" 
                round
                minimal={ value.filter( (e: any) => e !== v ).length > 0 }
                onClick={() => props.onMultipleClick( v )}
            >
                <div className='pl-3' dangerouslySetInnerHTML={{__html: __(v) }} />
            </BTag> 
            :
            null
}
const single = (props: any): JSX.Element | null =>
{
    const {v, value, i} = props
    //console.log( value )
    return  v?._id 
        ?
        <BTag 
            key={ v._id + "_" + i } 
            intent={v.intent}
            data-hint={__(v.hint)}
            className={`m-1 pr-3 py-1 ${v.disabled ? "opacity_75 hint hint--top " : ""} pointer`} 
            round  
            minimal={ value !== v._id }
            onClick={() => !v.disabled ? props.onClick(v._id) : null }
        >
            {
                v.disabled 
                    ? 
                    <div className='d-flex'>
                        <Icon icon="delete" intent="danger" className='pr-1'/>
                        <div className='opacity_5' dangerouslySetInnerHTML={{__html: __(v.title)}}/>
                    </div> 
                    : 
                    <div className='pl-3' dangerouslySetInnerHTML={{__html: __(v.title)}} />
                }
        </BTag>
        :
        typeof v === "string"
            ? 
            <BTag 
                key={v + "_" + i} 
                data-value={ v }  
                className="m-1 px-3 py-1 pointer" 
                round
                minimal={ value !== v }
                onClick={() => props.onClick( v )}
            >
                { __(v) }
            </BTag> 
            :
            null
}