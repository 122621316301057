import { ID } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { Collapse } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { trimContent } from "../../about/FestTrackCard"
import TrackVKLink from "../../utils/TrackVKLink"
import TrackVideo from "../../utils/TrackVideo"
import getModeratorAvatar from "../../utils/getModeratorAvatar"
import TrackEditor from "./TrackEditor"
import TrackClosedLabel from "../../utils/TrackClosedLabel"

/* Protopia Ecosystem component */
export interface ITrackTaxProps {
    filter: ID[]
}

const TrackTax = (props: ITrackTaxProps): JSX.Element => { 
    const [current, setCurrent] = useState<ITrack | null>( null )
    const PEFestTrack: ITrack[] = useDataStore((state: any) => state.PEFestTrack )
    useEffect(() => {
        if( props.filter[0] === -1 ) {
            setCurrent( null )
        }
        else {
            const track: ITrack = PEFestTrack?.filter((track: ITrack) => track.id === props.filter[0]?.toString() )[0]
            setCurrent( track ? track : null )
        }
    }, [ props.filter, PEFestTrack ])

    return <>
        <Collapse 
            className=' container-float' 
            isOpen={ !!current && props.filter[0] !== -1 } 
            transitionDuration={ 1000 } 
        >
            <CurrentTrackTax track={current} />
        </Collapse>    
    </>
}
export default TrackTax 
 
interface ICurrentTrackTaxProps {
   track: ITrack | null
}
const CurrentTrackTax = ({track, ...props}: ICurrentTrackTaxProps): JSX.Element => { 
    const[isOpen, setOpen] = useState(false)
    const trimmedContent = trimContent( track?.content || "", 30 ) 
    
    return <div className='w-100'> 
        <div className=" light-colored  m-0 flex-row">
            <div className="container d-flex flex-md-row flex-column">
                <div 
                    className="w-md-33 flex-grow-100 position-relative overflow-hidden pointer "
                    style={{
                        backgroundImage:`url(${ track?.illustration })`, 
                        minHeight: 250,
                        backgroundSize: 'cover',
                        backgroundPosition: "center"
                    }}
                    onClick={() => setOpen( !isOpen )}
                >
                    <TrackVKLink item={track || {} as ITrack} />
                    <TrackVideo  item={track || {} as ITrack} />
                    <TrackClosedLabel is_closed={!!track?.is_closed} />
                </div>
                <div
                    className="w-md-66 flex-grow-100 p-0 " 
                >
                    <div className="lead p-3 align-items-center ">
                        <img src={ track?.thumbnail } alt="" className='track-miniature'/> 
                        { track?.title }
                        <TrackEditor item={track} />
                    </div>
                    <div onClick={() => setOpen( !isOpen )} className="pointer">
                    {
                        trimmedContent.isTrim || track?.moderator?.user_descr?.length > 0
                            ?
                            <>
                                <Collapse isOpen={!isOpen} className={` ${ !isOpen ? "cclosed" : "" } `}>
                                <div className="px-4 pt-2 pb-2 flex-centered flex-column content-collapse">
                                    <div className="h-100 text-clip--transparent" dangerouslySetInnerHTML={{ __html: trimmedContent.content }} />
                                    
                                </div>                                               
                                </Collapse>
                                <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                                <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                                    <div 
                                    className="h-100" 
                                    dangerouslySetInnerHTML={{ __html: track?.content || "" }} 
                                    />
                                </div>
                                </Collapse>       
                            </>
                            : 
                            <>
                                <div className="px-4 py-2">
                                    { trimmedContent.content }
                                </div> 
                            </>
                    }
                    </div> 
                    {
                        track && getModeratorAvatar(track, true, Math.random(), "moderator")
                    }
                    {
                        track && getModeratorAvatar(track, true, Math.random(), "moderator2")
                    }
                    {/* <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                        <TrackLastAdlabel item={ track?.last_ad } track={ track } />                                          
                    </Collapse>
                    <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                        <div className="d-flex">
                            <TrackAttachmentlabel item={ track } />
                        </div>                             
                    </Collapse> */}
                </div>
                
            </div> 
        </div>
    </div>
} 