import { ID, IPaging, ITaxonomyFilter } from '@/libs/interfaces/layouts'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getQueryData, schema } from 'src/libs/layouts'
import Feed from '../../../pe-basic-module/views/core/Feed'
import { actions } from '../../data/actions'
import { BULK_DOUBLE_ACTION } from '../../data/actionTypes'
import { IAdminStore, IBulk, useAdminStore } from '../../data/store'
import DataTableFiltersPanel from './DataTableFiltersPanel'
import DataTableLayout from './DataTableLayout'
import DataTypeRow from './DataTypeRow'

export const DataTable = ( props:any ) : JSX.Element => {
    const params = useParams() 
    const landId : ID = props.landId ? props.landId : params.landId || ""
    const [count, setCount] = useState<number>(props.count || 10 )
    const bulks: IBulk[]  = useAdminStore((state:IAdminStore) => state.bulks) 
    const items: any[]  = useAdminStore((state:IAdminStore) => state.items) 
    const setBulks: ((bulks: IBulk[]) => void)  = useAdminStore((state:IAdminStore) => state.setBulks) 
    const setItems: ((items: any[]) => void)  = useAdminStore( (state:IAdminStore) => state.setItems ) 
    const data_type: string = useAdminStore((state:IAdminStore) => state.data_type) 
    const setData_type: ((data_type: string) => void)  = useAdminStore( (state:IAdminStore) => state.setData_type ) 
    const offset: number = useAdminStore((state:IAdminStore) => state.offset) 
    const setOffset: ((offset: number) => void)  = useAdminStore( (state:IAdminStore) => state.setOffset ) 
    const paging: string = useAdminStore((state:IAdminStore) => state.paging) 
    const setPaging: ((paging: string) => void)  = useAdminStore( (state:IAdminStore) => state.setPaging ) 
    const [isLoading, setIsLoading] = useState<boolean>(true) 

    useEffect(() => {
        setBulks([])
        setItems([])
        setOffset(0)
        setPaging("")
        setData_type(props.data_type)
        return () => {
            setBulks( [] )
            setItems( [] )
            setData_type( "" )
            setOffset(0)
            setPaging("")
        }
    }, [])
    useEffect(() => {
        setBulks([])
        setItems([])
        setOffset(0)
        setPaging("")
        setData_type(props.data_type)
    }, [ props.data_type ])

    const onLoading = ( items: any[],  _offset: number, count: number, pgng: string ) => {
        if( bulks.length !==  items.length ) {
            const _bulks: IBulk[] = items.map( (item: IBulk) => {
                return {
                    id: item.id,
                    data_type,
                    checked: false
                }
            })
            //console.log(_bulks)
            setBulks( _bulks ) 
        }   
        setIsLoading(false)
        // отсекаем все срабатывания, кроме измениния paging
        if(JSON.stringify(paging.replaceAll(" ", "")) === JSON.stringify(pgng.replaceAll(" ", ""))) {
            return
        }
        setOffset( _offset )
        setPaging( pgng )
        setItems(items || [])
    }
    const onOffset = ( _offset: number ) => { 
        console.log(`onOffset`)
        // setOffset( _offset )
        useAdminStore.setState({offset:_offset})
        //setTimeout( () => console.log( "onOffset...zustand store:", offset, "Feed:", _offset ), 600)
        setIsLoading(true)
    }
    const onChangeCount = ( count: number ) => {
        //console.log(`onChangeCount`)
        setCount(count)
    }
    const clearFilter = () => {

    } 
    const onFilter = ( pgObj: IPaging ) => {
        console.log( pgObj.taxonomies )
        let paging: string = ``, relations =``, metas = ``, in_roles = ``, search = ``, taxonomies = ``
        if( pgObj.taxonomies && !!pgObj.taxonomies.filter(t => !!t.term_ids.length).length ) {
            const txs = "[" + pgObj.taxonomies.map(( t:ITaxonomyFilter ) => {
                return !!t.term_ids.length ? ` { tax_name :"${t.tax_name}", term_ids:[${t.term_ids.join(",")}] } ` : ``
            }).join(", ") + "] "
            taxonomies = ` taxonomies:${ txs } `
        }
        if( pgObj.search ) {
            search = ` search:"${ pgObj.search }" `
        }
        if( pgObj.post_status && Object.keys( schema()[ props.data_type ].apollo_fields ).includes("post_status") ) {
            relations = ` post_status:"${ pgObj.post_status }" `
        }
        if( pgObj.metas?.length && pgObj.metas?.length > 1 ) {
            relations = ` relation:"${ pgObj.relation }" `
        }
        if( !!pgObj.metas?.length) { 
            metas = " metas: [" + 
                pgObj.metas.map(m => { 
                    const value = typeof m.value !== "boolean" ? `"${m.value}"` : m.value ? '"1"' : '""'
                    return ` {key:"${m.key}", value:${value}} `
                }).join(", ") + 
            "] "           
        }
        if( Array.isArray(pgObj.in__roles) && !!pgObj.in__roles?.length ) {
            in_roles = " role__in: [" +
                pgObj.in__roles.map(ir => {
                    return ` "${ir}" `
                }).join( " ") + 
                "] "
        }
        paging = `${relations}${paging}${metas}${in_roles}${search}${taxonomies}` 
        console.log( paging )
        setPaging(paging)
        setIsLoading(true)
    }
    const onDoubled = () => {
        setIsLoading(true)
        actions(
            BULK_DOUBLE_ACTION, 
            { 
                data_type: props.data_type, 
                id: bulks.filter( b => b.checked ).map(b => b.id), 
                land_id: landId,
                offset,
                paging
            }
        )
        .finally( () => setIsLoading( false ) )
    }

    const onMainBulk = (bool:boolean) => {
        console.log( bool )
    } 

    return <div className={`${isLoading ? "opacity_5" : ""}`}> 
        <DataTableFiltersPanel
            data_type={ props.data_type }
            count={count}
            onChangeCount={onChangeCount}
            clearFilter={clearFilter}
            onFilter={onFilter}
            onDoubled={onDoubled}
        /> 
        <Feed 
            data_type={ props.data_type } 
            isLoader
            count={20} 
            offset={0} 
            order= { props.order }
            filter={ props.filter }
            class_name='row'
            containerClassName="container-fluid"
            component={ DataTypeRow }
            layoutComponent={ DataTableLayout }
            onLoading={ onLoading }
            setOffset={onOffset}
            items={ items }
            land_id={ landId }
            paging={ 
                ` ${paging} ` 
            }
            params={{
                fields: getQueryData( props.data_type ),
                route: props.route,
                onMainBulk
            }}
        />  
        
    </div>
}
