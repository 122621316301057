import { ID } from '@/libs/interfaces/layouts'
import { Loading } from '@/libs/useful'
import { __ } from '@/libs/utilities'
import actions from '@/modules/pe-basic-module/data/actions'
import { GET_FEED_ACTION } from '@/modules/pe-basic-module/data/actionTypes'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import NoMatchView from '@/modules/pe-basic-module/views/NoMatchView'
import { IState, IUser, useMainStore } from '@/settings/zustand'
import { isRole } from '@/settings/zustand/user'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { IPEFestSchool, IProject, ITrack } from '../../data/interfaces'
import { useFestival } from '../../hooks/festival'
import MySchool from './MySchool'
import MyTrack from './MyTrack'

const ModerationForm = (props: any): JSX.Element => { 
    const params = useParams()
	const {landId} = params 
    const isLoading = useFestival(true, landId) 
    if(isLoading) return <Loading />
    return <div className="pt-3 container"> 
    {
        isRole([ "SuperAdmin", "administrator", "Track_moderator", "School_guardian" ])
            ?
            <>
				<ModerationContent />
				<ModerationSchool />
			</>
			:
			<ModerationNone />
    } 
    </div>
} 
export default ModerationForm

const ModerationNone  = () : JSX.Element => {
    return <NoMatchView />
}

const ModerationContent = ( ) :JSX.Element| JSX.Element[] | undefined => {    
	const {landId} = useParams() 
    const user:IUser = useMainStore( (state:IState) => state.user )
    const [isLoading, setIsLoading] = useState<boolean>( true )
	const isFestLoading: boolean = useFestival( true, landId )
	const tracks: ITrack[] = useDataStore((state:any) => state.PEFestTrack || []) 
	const [projects, setProjects] = useState<IProject[]>([])	 
    useEffect(() => {  
		actions(GET_FEED_ACTION,
			{
				data_type: "PEFestProject", 
				paging: ' post_status: "all" ',
				//count: 5, 
				land_id: landId 
			})
			.then((response: any) =>  {
				setProjects( response.feed )
				setIsLoading(false)
			}) 
    }, [])
    if( isFestLoading && !isLoading ) return <Loading />
	const onChangeProject = (id: ID, field: keyof IProject, value: any) => {
		setProjects( 
			 projects.map(pr => ({
				...pr,
				[field] : pr.id === id ? value : pr[field]
			}))
		)
	}
    return !!projects.length 
        ?
		<>
			{
				isRole([ 'Track_moderator' ]) &&  tracks
					.filter((track: ITrack) => {
						return (track.moderator?.id === user.id || track.moderator2?.id === user.id) 
					})
					.map(( track : ITrack ) => <MyTrack 
						key={ track.id }
						track={ track }
						projects={projects.filter( pr => pr.track?.id === track.id )}
						route=""
						onChangeProject={onChangeProject}
						setIsLoading={setIsLoading}
					/>) 
			}
			{
				isRole([ "SuperAdmin", 'administrator' ]) && 
					<MyTrack 
						track={ {} as ITrack } 
						projects={projects.filter(pr => !pr.track?.id )} 
						route="" 
						onChangeProject={onChangeProject}
						setIsLoading={setIsLoading}
					/>
			}
		</>
        :
        !isRole([ "SuperAdmin", 'administrator', 'Track_moderator' ]) 
			?
			<div className="alert alert-danger p-5 lead text-center">
				{
					__("No elements exists")
				}
			</div>
			:
			<div/>
}

const ModerationSchool = () :JSX.Element| JSX.Element[] => { 
	const {landId} = useParams()    
	const user:IUser = useMainStore( (state:IState) => state.user )
    const [isLoading, setIsLoading] = useState<boolean>( true )
	const isFestLoading: boolean = useFestival( true, landId )
	const [schools, setSchools] = useState<IPEFestSchool[]>([])	 
	const [projects, setProjects] = useState<IProject[]>([])	 
    useEffect(() => { 
		actions(GET_FEED_ACTION,
			{
				data_type: "PEFestSchool", 
				paging: ` metas: [{key: "guardian_id", value: "${user.id}"}, {key: "guardian_id2", value: "${user.id}"} ],  relation: "OR" `, 
				land_id: landId 
			})
			.then((resp: any) =>  { 
				console.log( resp.feed )
				setSchools( resp.feed )
				actions(GET_FEED_ACTION,
					{
						data_type: "PEFestProject", 
						paging: ` post_status: "all", taxonomies:[{tax_name: "fmru_school", term_ids:[${resp.feed.map((s: any) => s.id).join(",")}] }] `, 
						land_id: landId 
					})
					.then((response: any) =>  {
						setProjects( response.feed )
						setIsLoading(false)
					})
			})
    }, [])
    if( isFestLoading && !isLoading ) return <Loading />
	const onChangeProject = (id: ID, field: keyof IProject, value: any) => {
		setProjects( 
			 projects.map(pr => ({
				...pr,
				[field] : pr.id === id ? value : pr[field]
			}))
		)
	}
    return !!projects.length 
        ?
		<> 
			{
				isRole([ "School_guardian" ]) && 
				<MySchool 
					schools={schools}
					projects={projects}
					onChangeProject={onChangeProject}
				/>
			}
		</>
        :
        <div className="alert alert-danger p-5 lead text-center">
            {
                __("No elements exists")
            }
        </div>
}
