import React from 'react' 
import { NavLink } from 'react-router-dom'
import { __, initArea } from 'src/libs/utilities' 
import { IFestival } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import PhaseLabel from '../PhaseLabel'
import { Button } from '@blueprintjs/core'

const Statistics = (props: any) : JSX.Element => {
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    return <div className="row justify-content-center ">
        <div className="col-md-12 d-flex justify-content-center pt-4 ">
            <div className="role_descr">
            { __( "Statistics" ) }
            </div>
        </div>
        <div className="col-md-12 d-flex justify-content-center py-4 ">
            <div className="row w-100">
                <div className="test-descr-title col-md-6">
                    { __( "Count of projects" ) }
                </div>
                <div className="test-descr-cont title col-md-6">
                    { festival.memberCount }
                </div>
                <div className="test-descr-title col-md-6">
                    { __( "Count of assessments" ) }
                </div>
                <div className="test-descr-cont title col-md-6">
                    { festival.countOfResults }
                </div>        
                <div className="test-descr-title col-md-6 my-auto">
                    { __( "Current status" ) }
                </div>
                <div className="test-descr-cont title col-md-6">
                    <PhaseLabel /> 
                </div>
                {
                    initArea("pe-fest-statistic", { ...props })
                }
                <div className=" col-md-12 flex-centered ">
                    <NavLink to="rate-system" className="my-2">
                        <Button minimal className='text-force-contrast text-light'>{ __( "Rate System" ) }</Button>
                    </NavLink>
                </div>
            </div>
        </div> 
    </div>
} 

export default Statistics