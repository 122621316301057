import { ID, ITax } from '@/libs/interfaces/layouts'
import { SCALAR_TYPES, VerticalType } from '@/libs/scalars/interface/IScalarForm'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import LoginRegisterForm from '@/modules/pe-basic-module/views/profile/LoginRegisterForm'
import { IState, IUser, useMainStore } from '@/settings/zustand'
import { Callout, Icon, Intent } from '@blueprintjs/core'
import { useEffect, useMemo, useState } from 'react'
import FieldInput from "src/libs/scalars"
import { AppToaster, MediaChooser } from 'src/libs/useful'
import { __, isEmail, sprintf } from 'src/libs/utilities'
import { IFestival, IFormField, IPEFestSchool, ITrack } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import KraefestRegisterForm from '../../widgets/KraefestRegisterForm'
import EditProjectTax from './EditProjectTax' 

/*
    Публичная форма для регистрации / редактирования своего проекта Пользователями на фронте
*/

interface IProps {
    id?: ID
    title?: string
    thumbnail?: string
    content?: string
    tutorName?: string
    tutor_email?: string
    track?: ID
    school?: ID
    destrict?: ID
    ganres?: ID[]
    fmru_school?: IPEFestSchool[]
    isNew?: boolean
    form_fields?: IFormField[][]
    isLoading: boolean
    isLocked: boolean
    isReadyUpdate: boolean
    onUpdate: (data: any) => void 
}  

const fillFields = (matrix: IFormField[], data: any) => { 
    if(!Array.isArray(data)) {
        data = []
    }
    return matrix?.map( (fMatrix: IFormField, i: number ) => {
        return !data[i]
            ?
            []
            :
            data[i]
    })
}

const EditProjectForm = (props:IProps) : JSX.Element => {
    const festival: IFestival = useFestDataStore( (state: IFestDataStor) => state.festival )
    const tracks: ITrack[] = useDataStore( (state: any) => state.PEFestTrack )
    const schools: ITax[] = useDataStore( (state: any) => state.PEFestSchool )
    const allGanres: ITax[] = useDataStore( (state: any) => state.PEFestGanre )
    const user: IUser = useMainStore((state: IState) => state.user )
    
    const [title, setTitle] = useState(props.title)
    const [thumbnail, setThumbnail] = useState(props.thumbnail || "")
    const [thumbnailName, setThumbnailName] = useState<string>('')
    const [content, setContent] = useState<string>(props.content || "")
    const [tutorName, setTutorName] = useState<string>(props.tutorName || "")
    const [tutor_email, setTutor_email] = useState<string>(props.tutor_email || "")
    const [leaderName, setLeaderName] = useState<string>(props.tutorName || "")
    const [leader_email, setLeader_email] = useState<string>(props.tutor_email || "")
    const [user_role, setUserRole] = useState<"tutor"| "leader">("leader")
    const [track, setTrack] = useState<ID>( props.track || "") 
    const [school, setSchool] = useState<ID>( props.school || "") 
    const [ganres, setGanres] = useState<ID[]>( Array.isArray(props.ganres) ? props.ganres : []) 
    const [destrict, setDestrict] = useState<ID>( props.destrict || "") 
    const [isEmailCorrect, setEmailCorrect] = useState<boolean>(false)
    const [isLeaderEmailCorrect, setLeaderEmailCorrect] = useState<boolean>(false)
    const [form_fields, setForm_fields] = useState<IFormField[][]>( fillFields( festival.extendedFields || [], props.form_fields || [] as IFormField[][] ) )
    const [isReadyUpdate, setIsReadyUpdate] = useState(false)
    
    const fieldData: IFormField[][] = useMemo<IFormField[][]>(() => {
        return fillFields( festival.extendedFields || [], props.form_fields || [] as IFormField[][] )
    }, [ ])
    
    useEffect(() => {
        setIsReadyUpdate( props.isReadyUpdate )
    },[ props.isReadyUpdate ])
     
    if(!festival.isCreateAuthorWithProject && !user.id ) {
        return <div className="layout-state">
            <LoginRegisterForm />
        </div>
    }

    const onThumbnail = (thumbnail: any, file: any, boo:any) => {
        setThumbnail(thumbnail)
        setThumbnailName(file?.name)
        setIsReadyUpdate(true)
        console.log(thumbnail, file, boo)
    }
    const onTutorEmail = (evt: any) => {
        const tutor_email = evt.currentTarget.value
        if( isEmail( tutor_email ) )
        {
            setEmailCorrect(true)
        }
        else
        {
            setEmailCorrect(false)
        }
        setTutor_email( tutor_email )
        setIsReadyUpdate(true)
    }
    const onLeaderEmail = (evt: any) => {
        const leader_email = evt.currentTarget.value
        if( isEmail( leader_email ) )
        {
            setLeaderEmailCorrect(true)
        }
        else
        {
            setLeaderEmailCorrect(false)
        }
        setLeader_email( leader_email )
        setIsReadyUpdate(true)
    }
    const onChangeUserRole = (value: any) => {
        console.log(value._id)
        setUserRole(value._id)
    }
    const onSchool = ( item: any ) => {  
        setSchool( item.id )
        setIsReadyUpdate(true)
    }
    const onGanre = ( item: ITax[] ) => { 
        // console.log( item )
        if(!Array.isArray(item )) {
            item = [ item ]
        }
        if( item.length  > (festival.maxGanres || 1) ) {
            AppToaster.show({
                message: sprintf( __("Select less %s ganres"), (festival.maxGanres || 1).toString() ),
                intent: Intent.DANGER
            })
            return
        }
        const ganres = allGanres
            .map((t:ITax) => t.id)
                .filter( (t) => !!item.filter( ( it: ITax ) => it.id === t ).length )
        // console.log( ganres )  
        setGanres(  ganres )
        setIsReadyUpdate(true)
    }
    const onDestrict = ( item: any ) => {  
        setDestrict( item.id )
        setIsReadyUpdate(true)
    }

    const onExtendFetchParams = ( value: any, field: string, n: number, i: any ) => {
        // return
        let val: any = value
        if( ( festival.extendedFields || [] )[ n ].type === SCALAR_TYPES.GEO )
        {
            val = JSON.stringify(value)
        } 
        let fields: IFormField[][] = [...form_fields].map( f => [ ...( f || [] ) ] ) 
        fields[n].splice(i, 1, val) 
        console.log( fields )
        setForm_fields( fields )
        setIsReadyUpdate(true)
    }
    const onUpdate = () => {             
        if( !checkRequired() ) return
        const item: any = {
            id : props.id,
            title, 
            content,
            thumbnail,
            thumbnailName,
            track: Number(track),
            fmru_school: [Number(school)],
            fmru_destrict: [Number(destrict)],
            fmru_group_player: ganres.map( id => Number(id) ),
            tutorName,
            tutor_email,
            leaderName,
            leader_email,
            form_fields,
            user_role
        }
        props.onUpdate(item)
    }
    const checkRequired = () : boolean => {
        let yes = true
        const msgs: string[] = []
        if(!title) {
            yes = false
            msgs.push("Insert title") 
        }
        if( !track && !!tracks.length) {
            yes = false
            msgs.push("Need track") 
        }
        if( !school && !!schools.length) {
            yes = false
            msgs.push("Need school") 
        }
        if(
            festival.availableRoles?.includes("Tutor") && 
            festival.isCreateAuthorWithProject &&
            !isEmail( tutor_email )
        ) {
            yes = false
            msgs.push( "Email field not correct" )
        }
        if(
            festival.availableRoles?.includes("Tutor") && 
            festival.isCreateAuthorWithProject && 
            !tutorName
        ) {
            yes = false
            msgs.push("Insert Tutor name")
        } 
        if(
            festival.availableRoles?.includes("Project_author") && 
            festival.isCreateAuthorWithProject && 
            !tutorName
        ) {
            yes = false
            msgs.push("Insert Tutor name")
        } 
        if(
            !user_role
        ) {
            yes = false
            msgs.push("Choose your role")
        } 
        festival.extendedFields?.forEach(( field:IFormField, i:number ) => {
            if( field.require && (!form_fields[i] || !form_fields[i].filter( f => !!f ).length ) ) {
                yes = false
                msgs.push( __("Insert field ") + "<b>«" + field.name + "»</b>" )
            }
        }) 
        if( !!msgs.length ) {
            const _msgs: JSX.Element[] = []
            msgs.forEach(( current: string, i:number) => {
                _msgs.push(<div key={i} className="m-1 " dangerouslySetInnerHTML={{__html:__(current)}} />)
            })  
            AppToaster.show({
                message: <div className="p-3">
                    <div className="lead mb-2">
                        {__("The form is not completely filled out")}
                    </div>
                    {_msgs}
                </div>,
                intent: Intent.DANGER,
                timeout: 25000
            })   
        } 
        return yes
    }

    return <div className=" container pb-5 mb-5"> 
        <div className={`row ${ props.isLocked ? " untouchble opacity_5 " : "" }`}>
            <div className="col-12">
                <div className="row data-list">
                    <div className="col-md-4 col-sm-4 my-auto">
                        <div className='d-flex justify-content-between align-items-center w-100'>
                            <div className='require-sign mr-4 h-100'>
                                <div className='d-flex user-select-none descr text-danger'>
                                    {__("Required field")}
                                </div>  
                            </div> 
                            <div className="title text-danger">
                                {__("Project title")} :
                            </div>
                        </div>              
                    </div>
                    <div className="col-md-8 col-sm-8 pt-2">
                        <input
                            value={title || ""}
                            onChange={evt => {
                                let val = evt.currentTarget.value
                                val = val.replace(/(^| )"/g, '$1«')
                                val = val.replace(/"($|)/g, '»$1')
                                val = val.replace(/"($| )/g, '»$1')
                                val = val.replace(/-(^| )/g, ' — $1')
                                setTitle( val )
                                setIsReadyUpdate(true)
                            }}
                            className="input dark form-control" 
                        />
                    </div>
                </div>
                <div className="row data-list">
                    <div className="col-md-4 col-sm-4 title  my-auto text-right">
                        {__("Thumbnail")}:
                    </div>
                    <div className="col-md-8 col-sm-8 pt-2 pb-3"> 
                    <MediaChooser
                        prefix="_post_thumbnail_"
                        url={ thumbnail }
                        id=""
                        ID=""
                        isURLHide
                        hideLib={true}
                        limitSize={2000000}
                        height={200} 
                        padding={6}
                        tab="ld"
                        onChange={ onThumbnail }
                    />
                    </div>
                </div> 
                <div className="row data-list ">
                    <div className="col-md-4 col-sm-4 my-auto2">
                        <div className='d-flex justify-content-end align-items-center w-100'>                 
                            <div className="title ">
                                {__("Description")}:
                            </div>
                        </div>  
                    </div>
                    <div className="col-md-8 col-sm-8 ">
                        <textarea 
                            className="w-100 input dark m-0 " 
                            rows={10}  
                            onChange={ evt => {
                                let val = evt.currentTarget.value
                                val = val.replace(/(^| )"/g, '$1«')
                                val = val.replace(/"($|)/g, '»$1')
                                val = val.replace(/"($| )/g, '»$1')
                                val = val.replace(/-(^| )/g, ' — $1')
                                setContent( val )
                                setIsReadyUpdate(true)
                            }}
                            value={  content }
                        >
                            { content }
                        </textarea> 
                    </div>
                </div>
                {
                    festival.availableRoles && 
                    festival.availableRoles.includes( "Tutor" ) && 
                    festival.isCreateAuthorWithProject && 
                    <>
                        <div className="row data-list">
                            <div className="col-md-4 col-sm-4 my-auto">
                                <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className='require-sign mr-4 h-100'>
                                    <div className='d-flex user-select-none descr text-danger'>
                                    {__("Required field")}
                                    </div>  
                                </div> 
                                <div>
                                    <div className="title text-danger">
                                    {__("Tutor name")} :
                                    </div>                        
                                    <div className='descr'>
                                        { __( "" ) }
                                    </div> 
                                </div>
                                
                                </div>              
                            </div>
                            <div className="col-md-8 col-sm-8 pt-2">
                                <input
                                    disabled={!props.isNew}
                                    value={ tutorName || ""}
                                    onChange={ evt => {
                                        setTutorName( evt.target.value )
                                        setIsReadyUpdate(true)
                                    }}
                                    className="input dark form-control" 
                                />
                            </div>
                        </div>
                        <div className="row data-list">
                            <div className="col-md-4 col-sm-4 my-auto">
                                <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className='require-sign mr-4 h-100'>
                                    <div className='d-flex user-select-none descr text-danger'>
                                        {__("Required field")}
                                    </div>  
                                </div> 
                                <div className="text-right">
                                    <div className="title text-danger">
                                        {__("Tutor email")} :
                                    </div> 
                                </div>                      
                                </div>                       
                                <div className='descr text-right'>
                                    { __( "If this is a new email in the system, a new User will be created" ) }
                                </div>              
                            </div>
                            <div className="col-md-8 col-sm-8 pt-2 position-relative">
                                <input
                                    value={ tutor_email || "" }
                                    disabled={!props.isNew}
                                    onChange={ onTutorEmail }
                                    className={`input dark form-control ${ !isEmailCorrect ? "opacity_5" : ""} `}
                                />
                                <div className="small text-danger position-absolute bottom right mx-4 my-2">
                                    { __(  isEmailCorrect ?  "" : "uncorrect e-mail" ) }
                                </div>
                            </div>
                        </div>
                    </>
                } 
                {
                    festival.availableRoles && 
                    festival.availableRoles.includes( "Project_author" ) && 
                    festival.isCreateAuthorWithProject && 
                    <>
                        <div className="row data-list">
                            <div className="col-md-4 col-sm-4 my-auto">
                                <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className='require-sign mr-4 h-100'>
                                    <div className='d-flex user-select-none descr text-danger'>
                                    {__("Required field")}
                                    </div>  
                                </div> 
                                <div>
                                    <div className="title text-danger">
                                    {__("Project author name")} :
                                    </div>                        
                                    <div className='descr'>
                                        { __( "" ) }
                                    </div> 
                                </div>
                                
                                </div>              
                            </div>
                            <div className="col-md-8 col-sm-8 pt-2">
                                <input
                                    disabled={!props.isNew}
                                    value={ leaderName || ""}
                                    onChange={ evt => {
                                        setLeaderName( evt.target.value )
                                        setIsReadyUpdate(true)
                                    }}
                                    className="input dark form-control" 
                                />
                            </div>
                        </div>
                        <div className="row data-list">
                            <div className="col-md-4 col-sm-4 my-auto">
                                <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className='require-sign mr-4 h-100'>
                                    <div className='d-flex user-select-none descr text-danger'>
                                        {__("Required field")}
                                    </div>  
                                </div> 
                                <div className="text-right">
                                    <div className="title text-danger">
                                        {__("Project author email")} :
                                    </div> 
                                </div>                      
                                </div>                       
                                <div className='descr text-right'>
                                    { __( "If this is a new email in the system, a new User will be created" ) }
                                </div>              
                            </div>
                            <div className="col-md-8 col-sm-8 pt-2 position-relative">
                                <input
                                    value={ leader_email || "" }
                                    disabled={!props.isNew}
                                    onChange={ onLeaderEmail }
                                    className={`input dark form-control ${ !isLeaderEmailCorrect ? "opacity_5" : ""} `}
                                />
                                <div className="small text-danger position-absolute bottom right mx-4 my-2">
                                    { __(  isLeaderEmailCorrect ?  "" : "uncorrect e-mail" ) }
                                </div>
                            </div>
                        </div>
                    </>
                } 
                {
                    props.isNew &&
                    <>
                        <div className="row data-list">
                            <div className="col-md-4 col-sm-4 my-auto">
                                <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className='require-sign mr-4 h-100'>
                                    <div className='d-flex user-select-none descr text-danger'>
                                    {__("Required field")}
                                    </div>  
                                </div> 
                                <div>
                                    <div className="title text-danger">
                                        {__("Choose your role in your Project")} :
                                    </div>                        
                                    <div className='descr'>
                                        { __( "" ) }
                                    </div> 
                                </div>
                                
                                </div>              
                            </div>
                            <div className="col-md-8 col-sm-8 pt-2">
                                <FieldInput
                                    value={user_role}
                                    onChange={onChangeUserRole}
                                    type={SCALAR_TYPES.IMAGE_RADIO}
                                    editable
                                    vertical={VerticalType.VERTICAL}
                                    values={[
                                        {
                                            _id: "tutor",
                                            title: "Tutor",
                                            img: "/assets/img/fest/tutor_img.svg",
                                            width: 50,
                                            height: 50
                                        },
                                        {
                                            _id: "leader",
                                            title: "Project author",
                                            img: "/assets/img/fest/pupil_img.svg",
                                            width: 50,
                                            height: 50
                                        },
                                    ]}
                                />
                            </div>
                        </div> 
                    </>
                } 
                {
                    Array.isArray( tracks) &&  tracks.length > 0 && 
                    <div className="row data-list">
                        <div className="col-md-4 col-sm-4 my-auto">
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className='require-sign mr-4 h-100'>
                                    <div className='d-flex user-select-none descr text-danger'>
                                        {__("Required field")}
                                    </div>  
                                </div> 
                                <div className="text-right">
                                    <div className="title text-danger">
                                        {__("Track")} :
                                    </div> 
                                </div>                      
                            </div> 
                        </div> 
                        <div className="col-md-8 col-sm-8 p-2">
                            <FieldInput 
                                type={ "tags" }
                                values={ tracks?.map(ms => {
                                    return {
                                        _id : ms.id,
                                        title: ms.title,
                                        disabled: ms.is_closed,
                                        hint: __("Resister closed!")
                                    }
                                    }) }
                                className={` w-100 input dark form-control `}
                                vertical={VerticalType.VERTICAL}
                                editable={ props.isNew }
                                value={ track }  
                                onChange={ id => {
                                    setTrack(id)                                 
                                    setIsReadyUpdate(true)
                                }}
                            /> 
                        </div>
                    </div>  
                }
                 
                <EditProjectTax 
                    data_type="PEFestSchool"
                    isRequired={true}
                    isEditable 
                    item={ school || "-1" }
                    onChange={ onSchool }
                />
                {
                    festival.isGanres &&
                    <div className="row data-list">
                        <div className="col-md-4 col-sm-4 my-auto">
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className='require-sign mr-4 h-100'>
                                    <div className='d-flex user-select-none descr text-danger'>
                                        {__("Required field")}
                                    </div>  
                                </div> 
                                <div className="text-right">
                                    <div className="title text-danger">
                                        {__("Ganres")} :
                                    </div> 
                                </div>                      
                            </div> 
                        </div> 
                        <div className="col-md-8 col-sm-8 p-2">
                            <FieldInput 
                                type={ SCALAR_TYPES.IMAGE_CHECKBOX }
                                values={ allGanres?.map(ms => {
                                    return {
                                        _id : ms.id,
                                        title: ms.title,
                                        img: ms.thumbnail,
                                        height: 75
                                    }
                                    }) }
                                vertical={VerticalType.VERTICAL}
                                className={` w-100 input dark form-control `} 
                                editable 
                                multiple 
                                value={ ganres.map( g => ({
                                    _id: g
                                })) }  
                                onChange={ onGanre }
                                commentary={ sprintf(__("Select less %s ganres"), (festival.maxGanres || 1).toString()) }
                            />
                        </div>
                    </div>
                } 
                <KraefestRegisterForm
                    // form_fields={form_fields}
                    form_fields={ fieldData }
                    role="Tutor"
                    onExtendFetchParams={onExtendFetchParams}
                /> 
                {/* {
                    initArea(
                        "fest-register-form",
                        {
                            ...props,
                            form_fields,
                            role : "Tutor",
                            onExtendFetchParams
                        }
                    )
                } */}
                <div className="col-md-8 offset-md-4  p-4">
                    <div 
                        className={`btn ${
                            isReadyUpdate 
                                ?
                                " btn-danger "                                 
                                :
                                " untouchble btn-link "
                            }`
                        } 
                        onClick={ onUpdate }
                    >
                        <Icon 
                            icon={
                                isReadyUpdate 
                                    ? 
                                    props.isLoading 
                                        ? 
                                        "refresh" 
                                        : 
                                        "blank" 
                                    : 
                                    "tick"
                            } 
                            className={ props.isLoading ? "fa fa-spin mr-3" : " mr-3"} 
                            size={20} 
                        />
                        { __( !isReadyUpdate ?  "Change data" : props.isNew ? "Insert new Project" : "Update Project") }
                    </div>
                </div>
            </div>
        </div>
        <div className={` row pb-5 mb-5 ${ props.isLocked ? " untouchble opacity_5 " : "" }`}>
            <Callout className="col-12 descr m-1 text-center">
                {__("You can add team members after registering the project. In the «Project Team» tab")}
            </Callout>                
        </div>
    </div>
}

export default EditProjectForm
