import { __ } from "@/libs/utilities"
import { IPEFestSchool, IProject, ITrack, SPECIAL_SORT_TYPE } from "../../data/interfaces" 
import { useDataStore } from "@/modules/pe-basic-module/data/store"

/* Protopia Ecosystem component */
export interface ISpecialTitleRowProps {
    sortField: SPECIAL_SORT_TYPE
    prev: IProject
    current: IProject
}
const SpecialTitleRow = (props: ISpecialTitleRowProps): JSX.Element => {
    switch(props.sortField) {
        case SPECIAL_SORT_TYPE.SCHOOLS_SORT:
            const is: boolean = props.prev?.fmru_school[0]?.id !== props.current?.fmru_school[0]?.id

            return is 
            ?
            <>
                <tr className='bg-secondary-light  border-top border-secondary border-2'>
                    <td colSpan={ 40 }>
                        <div className="lead" style={{height: 5}} /> 
                    </td>
                </tr>
                <tr className='bg-secondary-light '>
                    <td colSpan={ 40 }>
                        <div className=" py-3">
                            <span className="small text-secondary mr-2">
                                {__("Education organization")}:
                            </span> 
                            <SchoolTitle school={ props.current?.fmru_school[0] } />
                        </div> 
                    </td>
                </tr>
            </>
            :
            <></>
        case SPECIAL_SORT_TYPE.TRACK_SORT:
            const is2: boolean = props.prev?.track?.id !== props.current?.track?.id

            return is2 
            ?
            <>
                <tr className='bg-secondary-light  border-top border-secondary border-2'>
                    <td colSpan={ 40 }>
                        <div className="lead" style={{height: 5}} /> 
                    </td>
                </tr>
                <tr className='bg-secondary-light '>
                    <td colSpan={ 40 }>
                        <div className="py-3">
                            <span className="small text-secondary mr-2">
                               {__("Track")}:  
                            </span>
                            <TrackTitle track={ props.current?.track } />
                        </div> 
                    </td>
                </tr>
            </>
            :
            <></>
        default:
            return <></>

    }
}
export default SpecialTitleRow

interface ISchoolTitleProps {
    school: IPEFestSchool
}
const SchoolTitle = ({school}:ISchoolTitleProps):JSX.Element => {
    const schools : IPEFestSchool[] = useDataStore((state:any) => state.PEFestSchool)
    return <>
        <span className="lead">
            { school?.title }
        </span>
        <span className="mx-2 small text-secondary ">
            {__("School_guardians")} :
        </span>
        <span className=" title text-secondary">
            <span className="mx-2">
                { schools.find(t => t.id === school?.id)?.guardian?.display_name } 
            </span>
            <span className="mx-2">
                { schools.find(t => t.id === school?.id)?.guardian2?.display_name } 
            </span>
             
        </span>
    </>
}

interface ITrackTitleProps {
    track:ITrack
}
const TrackTitle = ({track}:ITrackTitleProps):JSX.Element => {
    const tracks : ITrack[] = useDataStore((state:any) => state.PEFestTrack)
    return <>
        <span className="lead">
            { track?.title }
        </span>
        <span className="mx-2 small text-secondary ">
            {__("Moderators")} :
        </span>
        <span className=" title text-secondary">
            <span className="mx-2">
                { tracks.find(t => t.id === track?.id)?.moderator?.display_name }
            </span>
            <span className="mx-2">
                { tracks.find(t => t.id === track?.id)?.moderator2?.display_name }
            </span>
        </span>
    </>
}