import { IUser } from '@/settings/zustand'
import { isRole } from '@/settings/zustand/user'
import SDiv from '@/libs/basic-view/SDiv'
import { Button, Callout, Collapse, Intent, Tag } from '@blueprintjs/core'
import { DIALOG_SIZE, Role } from '@/libs/interfaces/layouts'
import { __ } from '@/libs/utilities'
import { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { IFestival, IProject } from '../../data/interfaces'
import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from '../../data/store'
import { useOwnerPartner, useUserAction, useUserOwner } from '../../hooks/project'
import CorrectHoneycombs from './projectData/CorrectHoneycombs'
import CorrectNomination from './projectData/CorrectNomination'
import CorrectPrize from './projectData/CorrectPrize'
import CorrectRaiting from './projectData/CorrectRaiting'
import ExtendedForms from './projectData/ExtendedForms' 
import ConfirmButton from '@/libs/useful/ConfirmButton'
import actions from '@/modules/pe-basic-module/data/actions'
import { UPDATE_SINGLE_TYPE } from '@/modules/pe-basic-module/data/actionTypes'
import BanForm from './BanForm'
import TrackLabel from '../projectList/TrackLabel' 
import { component_url } from '@/libs/utilities/links'
import GanreLabel from '../projectList/search/GanreLabel'
import { getModeratorRoles } from '../../data/utils/getModeratorRoles'
 
const ProjectData = (props: any) : JSX.Element => {
    const { landId } = useParams()
    const [ isCollapseOpen, setIsCollapseOpen ] = useState( props.isCollapseOpen ) 
    const festival: IFestival   = useFestDataStore((state: IFestDataStor) => state.festival) || {}  
    const item: IProject = useFestProjects( (state:IFestProjectsProps) => state.currentProject ) || {}
    //const { item } = props 
    const toAdmin = `${component_url("FestivalAdminListView")}/${landId}/projects/${item.id}` 
    const isPartner:boolean = useOwnerPartner(item)
    const isOwner: boolean = useUserOwner( item ) 
    const isMayAction: boolean  = useUserAction( item ) 
    const getMemberRuleLabel = () =>
    { 
      return ( isOwner && !item.is_verified ) 
        ?
        ". waiting for confirmation of the registration from the track moderator."
        :
        isMayAction
            ?
            ""
            :
            ". Festival closed. " 
    }  
    const experts : JSX.Element | false = !!( item.experts || [] ).length &&
        <Collapsed
            isOpen={ true }
            title="Experts, who put raiting:"
            description={ <strong>{ item.experts.map((e: IUser) => e.display_name).join(", ")}</strong> } 
        />
    
    const onVerify = () => {
        actions(
            UPDATE_SINGLE_TYPE, 
            {
                landId,
                id: item.id,
                data_type: "PEFestProject",
                item: {
                    is_verified: true
                }
            }
        )
    }
    
    return <div className="row mt-0">
        <div className="col-12 flex-centered d-none">
        {
            
            isOwner
                ?
                <span className="my-project-label ">
                {
                    __("My project")
                }
                { 
                    __( getMemberRuleLabel() )
                }
                </span>
                :
                isPartner 
                  ?
                  <span className="my-honeycombs-label text-force-black">
                  {
                    __("My honeycombs's partner")
                  }
                  </span>
                  :
                null
        }
        </div>
        {
            item.prize || isRole(getModeratorRoles())
                ?
                <div className="flex-centered w-100 flex-md-row flex-column"> 
                    <div className=" text-md-right text-center text-secondary align-items-center d-flex justify-content-end">
                        <span className="mx-4">
                            {__("Prize:")}
                        </span> 
                    </div>
                    <div className=" title text-danger text-md-left text-center">
                        <CorrectPrize item={item} />  
                    </div> 
                </div>
                :
                null
        }
        {
            item.nomination || isRole(getModeratorRoles())
                ?
                <div className="flex-centered w-100 flex-md-row flex-column"> 
                    <div className=" text-md-right text-center text-secondary align-items-center d-flex justify-content-end">
                        <span className="mx-4">
                            {__("Nomination:")}
                        </span> 
                    </div>
                    <div className=" title text-danger text-md-left text-center">
                        <CorrectNomination item={item} />  
                    </div> 
                </div>
                :
                null
        }
        <div className="w-100 mb-3 overflow-hidden bg-dark-light mt-0">
            <div className="fluid-container d-flex">  
                <div className="row w-100">
                    <div className="col-lg-6">
                        <div className="row my-0 h-100">  
                            <div className="col-12 position-relative d-flex justify-content-end h-100 ">
                                <div className="w-100 h-100 ">
                                    <SDiv
                                        style={{
                                            height: "100%",
                                            cursor: "pointer",
                                            backgroundImage:`url( ${item.thumbnail} )`,
                                            backgroundSize: "contain",
                                            backgroundPosition:"top right",
                                            backgroundRepeat:"no-repeat",
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "start",
                                            minHeight: 230
                                        }} 
                                        onClick={ () => setIsCollapseOpen( !isCollapseOpen ) }
                                        label={ "" }
                                    >
                                        <>
                                            {
                                                isRole(["administrator", "SuperAdmin"]) && <NavLink
                                                    to={toAdmin}
                                                    className={"position-absolute ml-4 pl-1"}
                                                >
                                                    <Button minimal icon="unlock" />
                                                </NavLink>
                                            }
                                            <InsButton 
                                                setIsCollapseOpen={ setIsCollapseOpen }
                                                isCollapseOpen={ isCollapseOpen }
                                                minimal={isOwner || isPartner || isMayAction}
                                            /> 
                                        </>
                                               
                                    </SDiv>
                                    {
                                        isOwner
                                            ?
                                            <>                    
                                                <div className="mine-corner mine-corner2">
                                                    {__("My project")}
                                                </div>
                                            </>                   
                                            :
                                            isPartner 
                                                ?
                                                <div className="mine-honeycombs-corner mine-corner2">
                                                    {__("My honeycombs")}
                                                </div>
                                                :
                                                null
                                    } 
                                    {
                                        !item.is_verified && 
                                        <div className="mine-corner-no-verified">
                                            {__("Project has not yet been approved")}
                                        </div>
                                    } 
                                </div>
                                <div className="position-absolute bottom left pl-4">
                                    {
                                        !item.is_verified && item.verified_reason &&
                                        <Callout intent={Intent.DANGER} >
                                            <div className="p-4 lead text-center max-width-300">
                                                {item.verified_reason}
                                            </div>
                                        </Callout>
                                    }
                                    <BanForm item={item} />  
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="col-lg-6">   
                        <div className="row my-0">  
                            <div className="col-md-11">
                                <ul className="list-group list-group-flush">
                                    <Collapsed
                                        isOpen={ true }
                                        title="Designation"
                                        description={
                                            <div className="d-flex flex-wrap align-items-center w-100 " >
                                                <div className="project-order-label">{item.order}</div>
                                                <div
                                                    className={`flex-grow-1 display-6 pointer ${!item.is_verified ? "text-danger" : ""}`}
                                                    dangerouslySetInnerHTML={{ __html: item.title }}
                                                    onClick={ () => setIsCollapseOpen( !isCollapseOpen ) }
                                                />                                                 
                                                {
                                                    !item.is_verified
                                                        &&
                                                        (isRole( getModeratorRoles() ) 
                                                            ?
                                                            <ConfirmButton 
                                                                buttonClassName='small'
                                                                buttonIntent='danger'
                                                                dialogClasssName={ DIALOG_SIZE.MINIMAL }
                                                                buttonLabel={
                                                                    <div className='text-nowrap px-3'>
                                                                        {__("Do verify Project")}
                                                                    </div>
                                                                }
                                                                onConfirm={onVerify}
                                                            >
                                                                <div className="p-4 lead text-center ">
                                                                    {__("Are you want verify this project?")}
                                                                </div>
                                                            </ConfirmButton>
                                                            :
                                                            <div className="px-3 py-1 alert alert-danger text-uppercase">
                                                                {__("Project has not yet been approved")}
                                                            </div>
                                                        )
                                                } 
                                            </div>
                                        }
                                    />
                                    <Collapsed
                                        isOpen={ isCollapseOpen }
                                        title="Description"
                                        description={<div
                                            className="w-100"
                                            dangerouslySetInnerHTML={{ __html: item.post_content }}
                                        />}
                                    /> 
                                    {
                                        item.track?.id && 
                                        <Collapsed
                                            isOpen={ true }
                                            title="Track"
                                            description={
                                                 <div className="d-flex align-items-center ">
                                                    <TrackLabel 
                                                        className="bg-transparent flex-centered flex-column flex-md-row" 
                                                        track={props.item.track} 
                                                    />
                                                </div>
                                            }
                                        /> 
                                    } 
                                    {
                                        !!festival.isGanres && !!item.fmru_group_player.length && 
                                        <Collapsed
                                            isOpen={ isCollapseOpen }
                                            title="Ganres"
                                            description={
                                                 <div className="d-flex align-items-center ">
                                                {
                                                    item.fmru_group_player.map((ganre, i) => i < (festival.maxGanres || 1) && <GanreLabel ganre={ganre} key={ganre.id} /> )
                                                }
                                                </div>
                                            }
                                        /> 
                                    } 
                                    {
                                        Array.isArray(item.fmru_destrict) && 
                                        !!item.fmru_destrict.length && 
                                        <Collapsed
                                            isOpen={ isCollapseOpen }
                                            title="Destrict"
                                            description={
                                                 <div className="d-flex align-items-center "> 
                                                    { item.fmru_destrict[0].title }
                                                </div>
                                            }
                                        /> 
                                    } 
                                    {
                                        Array.isArray(item.fmru_school) && 
                                        !!item.fmru_school.length && 
                                        <Collapsed
                                            isOpen={ isCollapseOpen }
                                            title="Education organization"
                                            description={
                                                 <div className="d-flex align-items-center "> 
                                                    { item.fmru_school[0].title }
                                                </div>
                                            }
                                        /> 
                                    } 
                                    <Collapsed
                                        isOpen={ true }
                                        title="My honeycombs"
                                        description={
                                            <CorrectHoneycombs
                                                honeycombs={ item.honeycombs  || {} } 
                                                itemId={item.id} 
                                            />
                                        }
                                    />  
                                    {
                                        ( festival.availableRoles || [] ).filter( role => role === "Tutor" ).length > 0
                                            && ( isRole( getModeratorRoles() ) || isOwner )
                                            && item.tutor?.id 
                                            &&
                                            <Collapsed
                                                isOpen={ true }
                                                title="Tutor"
                                                description={ [item.tutor.display_name, item.tutor.user_email].join(" | ") }
                                            /> 
                                    }
                                    {
                                        ( festival?.availableRoles || [] ).filter( (role: Role) => role === "Project_author" ).length > 0 &&
                                            ( isRole( getModeratorRoles() ) || isOwner ) &&
                                            item.leader?.id  
                                            &&
                                            <Collapsed
                                                isOpen={ true }
                                                title="Project_author"
                                                description={ [item.leader.display_name, item.leader.user_email].join(" | ") } 
                                            /> 
                                    } 
                                    {
                                        ( isRole( getModeratorRoles() ) || isOwner ) &&
                                            item.leader?.id  
                                            &&
                                            <Collapsed
                                                isOpen={ isCollapseOpen }
                                                title="Team"
                                                description={ 
                                                    <div className='d-flex flex-wrap '>
                                                        {
                                                            Array(10).fill(0) 
                                                                .map((__, i) => {
                                                                    return item["member" + i]?.id
                                                                        ?
                                                                        <Tag minimal className='mr-1 mb-1 px-3' key={i}>
                                                                            { item["member" + i]?.display_name}
                                                                        </Tag>
                                                                        :
                                                                        null
                                                                })
                                                        }
                                                    </div>
                                                } 
                                            /> 
                                    } 
                                    <ExtendedForms 
                                        item={ item }  
                                        hidden="hide_in_page" 
                                        isCollapseOpen={isCollapseOpen}
                                        className='small'
                                    />
                                    <Collapsed
                                        isOpen={ true }
                                        title="Valuations:"
                                        description={ <div className="d-block">
                                            <CorrectRaiting
                                                itemId={item.id} 
                                                countedRating={ item.actualRating }
                                                correctMemberRate={ item.correctMemberRate }  
                                            />
                                            <div className="descr text-primary">
                                                { item.correctMemberRate?.description }
                                            </div>
                                        </div> } 
                                    /> 
                                    { experts }
                                </ul>
                            </div>
                        </div>
                    </div>  
                </div> 
            </div> 
        </div>
    </div> 
} 

export default ProjectData

interface ICollapsedProps {
    isOpen: boolean  
    vertical?:true
    title: string | JSX.Element
    description: string | JSX.Element
    className?: string
}
export const Collapsed = (props: ICollapsedProps) : JSX.Element => {
    const col1: string = props.vertical  ? "12" : "md-3 justify-content-md-end text-md-right "
    const col2: string = props.vertical  ? "12" : "md-9 justify-content-md-start text-md-left "
    return <Collapse isOpen={props.isOpen}>
        <li className="list-group-item transparent p-2">
            <div className="row m-0">
                <div className={`col-${col1} text-secondary ${props.className}`}> 
                       {  typeof props.title === "string" ? __(props.title) : props.title} : 
                </div>
                <div className={`col-${col2} critery_cell1`}>
                    { props.description }
                </div>
            </div>
        </li>
    </Collapse>
}

const InsButton = ({setIsCollapseOpen, isCollapseOpen, minimal}:any) :JSX.Element => {
    return <Button 
    minimal 
    className='bg-dark text-light '
    onClick={ () => setIsCollapseOpen( !isCollapseOpen ) } 
    icon={
        isCollapseOpen
            ?
            "chevron-up"
            :
            "chevron-down"
    }
>
    {__( minimal ? "" : isCollapseOpen ? "Abbreviated" : "Ins")}
</Button>
}