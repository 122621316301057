import { Link } from 'react-router-dom'
import { __ } from 'src/libs/utilities'
import { EMTY_IMAGE_NAME, ICardProjectProps, IFestival } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import getProjectLink from '../../data/utils/getProjectLink'
import { useOwnerPartner, useUserOwner } from '../../hooks/project'
import HoneycombsLabel from './HoneycombsLabel'
import MemberRatingDisplay from './MemberRatingDisplay'
import TrackLabel from './TrackLabel'
import GanreLabel from './search/GanreLabel'

const StrokeProject = (props: ICardProjectProps): JSX.Element => {
  const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival) 
  const isOwner: boolean = useUserOwner( props.item )
  const isPartner:boolean = useOwnerPartner(props.item)
  const url = getProjectLink(props.item.id)
  const table = {
    height: 105,
    overflow: "hidden",  
    display: "table", 
    padding: "5px 30px",
  }
  const cell = { display: "table-cell", verticalAlign: "middle" }
  const content: string = props.item.post_content.replace(/<[^>]+>/g, '').split(" ").slice(0, 12).join(" ") + "..."
  return <div className="ml-2 w-100 grey2 position-relative overflow-hidden"> 
    <Link to={url} className="row hover">
      <div className="col-lg-3 col-md-5 col-sm-6 col-12">
        <div
          className="card-img"
          style={{
            backgroundImage: `url( ${
              props.item.thumbnail && props.item.thumbnail.indexOf(EMTY_IMAGE_NAME) === -1  
                  ? 
                  props.item.thumbnail 
                  : 
                  festival.defaultProjectThumbnail 
          })`,
            backgroundColor: "#00000005",//"transparent",
            border: "0",
            height: "100%",
            overflow:"hidden",
            minHeight: 120
          }}
          data-mid={props.item.id}
        >
          <div className="card-id">{ props.item.order }</div>
          {/* <div className="card-icons">{ _ganres }</div> */} 
        </div>
      </div>
      <div
        className="col-lg-3 col-md-2 col-sm-4 col-8 d-flex flex-centered flex-column position-relative"
        style={table}
      >
        
          <h5
            className={`card-title ${!props.item.is_verified ? "opacity_5" : ""} `}
            style={cell}
          >
            {props.item.title}
          </h5>
          {
            props.item.prize
              ?
              <div className="data text-danger title"> 
                {props.item.prize} 
              </div>
              :
              null
          }     
          {
            props.item.nomination
              ?
              <div className="data text-danger text-uppercase small"> 
                {props.item.nomination} 
              </div>
              :
              null
          }           
      </div>
      <div
        className="col-lg-1 col-md-1 col-sm-2 col-4 d-flex position-relative"
        style={table}
      >            
        <MemberRatingDisplay 
          correct_member_rate={props.item.correctMemberRate}
          countedRating={props.item.r2}
          className={"text-dark "}
        />           
      </div>
      <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-secondary py-4 pl-md-0 pl-4 pr-5" style={{}} >
        <em><div style={cell} dangerouslySetInnerHTML={{ __html: content }} className='pb-3'/></em>
        <div className="d-flex align-items-center"> 
          {__("Track")}: 
          <TrackLabel track={props.item.track} className="title d-inline-flex align-items-center text-nowrap my-2 ml-2"/>
        </div>
        <div> {__("Honeycomb")}: <HoneycombsLabel honeycombs={props.item.honeycombs}  className="title d-inline my-2 ml-2"/> </div>
        <div className='d-flex align-items-center '>
          <span className="mr-1">
            {__("Ganres")}:
          </span>
          {
              props.item.fmru_group_player.map((ganre, i) => i < (festival.maxGanres || 1) && <GanreLabel ganre={ganre} key={ganre.id} small /> )
          }
        </div>
      </div>
      {
          !props.item.is_verified && 
              <div className="mine-corner-no-verified corner-card-project">
                  {__("Project has not yet been approved")}
              </div>
      }
    </Link>
        {/* {
          this.props.store.myTracks.filter(mtID => mtID == props.item.track.ID).length > 0
            ?
            <div className="my-track-label">
              { __( "My Track" ) }
            </div>
            :
            null
        } */}
        {
          isOwner
            ?
            <div className="mine-corner corner-3 ">
                { __( "My project" ) }
            </div>
            :                    
            isPartner 
              ?
              <div className="mine-honeycombs-corner mine-corner1">
                  {__("My honeycombs")}
              </div>
              :
              null
        }
        {
          props.isMineHoneycoms
            ?
            <div className="mine-honeycombs-corner">
              { __( "My honeycombs" ) }
            </div>
            :
            null
        }
  </div>
} 

export default StrokeProject