import { getFeedRoute, getRoute } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { IAds } from "@/modules/pe-topos-module/data/interfaces"
import { Link } from "react-router-dom"
import { ITrack } from "../../data/interfaces"
import TrackAdAdd from "../projectList/filter-terms/TrackAdAdd"

/* Protopia Ecosystem component */
export interface ITrackLastAdlabelProps {
   item?: IAds
   track: ITrack | null
}
const TrackLastAdlabel = (props: ITrackLastAdlabelProps): JSX.Element => {
    return <div className='pe-track-last-ad-label-container d-flex py-1 px-4  align-items-center'>
        <div className="width-200 min-width-200 max-width-200 d-flex">
            <div className="small mr-3 text-no-break">{ __( "Last add" ) }:</div>
        </div>            
        <i className="far fa-newspaper mr-3" />
        <div className=" ">
            {
                props.item 
                    ?
                    <>
                        <div className="small title text-uppercase">{ props.item!.title }</div>
                        <div className="small opacity_75">{ props.item!.post_content }</div> 
                    </>
                    :
                    <></>
            } 
            <div className="d-flex align-items-center">
                <Link className="btn btn-link small" to={`/${getRoute( getFeedRoute("Ad") )}`}>
                    <span className="small">
                        {__("Ads archive")}
                    </span>
                </Link> 
                <TrackAdAdd track={ props.track! } />
            </div>                
        </div>
    </div> 
}
export default TrackLastAdlabel