import { Button, ButtonGroup, Collapse, Popover, Position } from '@blueprintjs/core'
import { ID, WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { LayoutIcon } from '@/libs/useful'
import { __, compareArrays, sprintf } from '@/libs/utilities'
import React, { useEffect, useRef, useState } from 'react'
import { template } from 'src/libs/layouts'
import { IState, useMainStore } from '../../../settings/zustand'
import { SET_FILTERS_ACTIONS } from '../data/actionTypes'
import { syncAction } from '../data/actions'
import { IFestFilters, IFestival, IGanre } from '../data/interfaces'
import { IFestDataStor, IFestStore, useFestDataStore, useFestStore } from '../data/store'
import useClickOutside from './hooks/useClickOutside'
import useProjectListRoute from './hooks/useProjectListRoute' 
import { useDataStore } from '@/modules/pe-basic-module/data/store'

const GANRE_FILTER_ICON_URL = "/assets/img/fest/kabuki.svg"

const GanreFilterMenu = () : JSX.Element => {
    if(window.innerWidth < WINDOW_WIDTH.TABLET) return <></> 
    return <GanreFilter />
}
export const GanreFilter = () : JSX.Element => { 
    const festival : IFestival = useFestDataStore((state: IFestDataStor) => state.festival) 
    const ganres : IGanre[] = useDataStore((state:any) => state.PEFestGanre) || []
    const filters: IFestFilters = useFestStore((state: IFestStore) => state.filters )
    const setCurrentTitle = () => {
        if( filters.ganres.length === 1) {
            setTitle( "all Ganres" )
        }
        else if( filters.ganres.length === 2 ) {
            const sc = ganres.filter( s => s.id === filters.ganres[1] )[0] || { title: "all Schools" }
            setTitle( sc.title )
        }
        else {
            setTitle( sprintf( __("selected %s schools"), ( filters.schools.length - 1 ).toString()  ) )
        }  
    }
    const [title, setTitle] = useState( __( "all Ganres" ) )
    const [isOpen, setOpen] = useState(false)
    const [isCollapse, setCollapse] = useState(false)
    let timeOut: any = -1
     
    const myRef: React.MutableRefObject<null> = useRef( null )
    const onOpen = (isOpen:boolean) =>
    {
        setOpen(isOpen)
    }
    const handleLoginOpen = () =>
    {
        clearTimeout( timeOut )
        onOpen(true)
    }
    const isRoute: boolean = useProjectListRoute() && !!ganres.length && festival.isGanres!
    useClickOutside( myRef, onOpen, timeOut)

    useEffect( () => {
        setCurrentTitle()
    }, [ filters ] )

    const selectGanre = (id: ID) =>
    {
        const ganres = id.toString() === (-1).toString()
            ?
            [-1]
            :
            [ -1, id ]
        syncAction(SET_FILTERS_ACTIONS, { filters:{ ganres } } )
        setCurrentTitle()
        clearTimeout( timeOut )
        onOpen(false)
    } 
    const onMouseLeave = () =>
    {
        clearTimeout( timeOut )
        timeOut = setTimeout(() =>
        {
            onOpen(false)
        }, 1000)
    }  
    return <>   
        <div 
            className={`indicator dble lrg classic p-0 m-0 overflow-hidden ${ isRoute ? "" : " closed"}`}             
            onMouseLeave={onMouseLeave}
            ref={ myRef }
        >
            <div className={ `header-menu-element ${ template().header_menu } main-menu-filter-icon ` }  onClick={handleLoginOpen}>
                <LayoutIcon
                    src={ GANRE_FILTER_ICON_URL }
                    className="header-menu-icon"
                    isSVG={false}
                />
                <span className='header-menu-label px-2' title={ __( title ) }>
                    { __( title ) }
                </span>
            </div> 
            <Popover
              className="indicator-popover"
              popoverClassName="p-0" 
              position={ Position.BOTTOM_RIGHT }
              isOpen={isOpen}
              content={(
                <div className='overflow-y-auto' style={{maxHeight:"calc(100vh - 150px)"}}>
                    <ButtonGroup 
                        vertical 
                        fill 
                        minimal 
                        onMouseEnter={() =>  clearTimeout( timeOut ) }
                        style={{ minWidth: 200 }}
                    >
                        {
                            true //filters.honeycombs[0] !== -1 || filters.tracks[0] !== -1 
                                ?
                                <Button 
                                    className={`p-3 btn-stretch text-force-contrast ${ filters.ganres.length === 1 ? " bg-danger  text-light " : "" }`} 
                                    onClick={() => selectGanre( -1 ) }
                                >
                                    {__("all Ganres")}
                                </Button>
                                :
                                null
                        }
                        {
                            Array.isArray( ganres )
                                ?
                                <>
                                    <Button 
                                        icon="chevron-down" 
                                        minimal
                                        small
                                        className={ isCollapse ? "hidden" : " p-3 " } 
                                        onClick={( event: React.MouseEvent<HTMLElement, MouseEvent> ) => {
                                            setCollapse(true)
                                            onOpen(true)
                                            event.preventDefault()
                                            event.stopPropagation()
                                        }}
                                    />
                                    <Collapse isOpen={isCollapse} >
                                        <div className="d-flex flex-column">
                                        {
                                            ganres.map(( ganre: IGanre ) =>
                                                {
                                                    return <Button 
                                                        className={ `p-0 btn-stretch text-force-contrast ${ filters.ganres.includes( ganre.id ) ? " bg-danger text-light " : "" }` } 
                                                        key={ganre.id} 
                                                        onClick={() => selectGanre(ganre.id) }
                                                    >
                                                        <div className='d-flex w-100'>
                                                            <span className='flex-grow-100 p-3 small '>
                                                                { ganre.title }
                                                            </span>
                                                            <span className ='p-3 bg-secondary-super-light small '>
                                                                { ganre.count || 0 }
                                                            </span>
                                                        </div>
                                                    </Button>
                                                    
                                                })
                                        }                                    
                                        </div>
                                    </Collapse>
                                </>
                                :                        
                                <div className='p-3' >
                                    {__("no Tracks")}
                                </div>   
                        }
                    </ButtonGroup>
                </div>
              )}
            >
                <div style={{ marginTop: 0 }} />
            </Popover> 
        </div> 
    </> 
} 

export default GanreFilterMenu



 