import { Email, ID, URL, HTML, IMenuItem, POST_STATUS, IPost, Role, Json } from "src/libs/interfaces/layouts"
import {__} from 'src/libs/utilities'
import { IUser } from "src/settings/zustand"
import { SCALAR_TYPES } from "src/libs/scalars"
import { Color } from "react-color"
import { IAds, IEvent } from "@/modules/pe-topos-module/data/interfaces"

export interface IFestival {
    id? : ID
    ID?: ID
    url?: URL
    domain_type?: string
    descr?: string
    title?: string
    content?: string
    isExpertsCriteries?: boolean 
    isHideNotVerifiedProjects?: boolean
    enabledRules? : boolean 
	isIndependentGradeCritery?: boolean 
	isOwnerRate? : boolean 
	isGanres?: boolean 
	enabledReglament?: boolean 
	isRegisterProject?: boolean 
	isCreateAuthorWithProject?: boolean 
	isProjectsVerify?: boolean 
	isShowMap?: boolean 
    isShowReglament?: boolean 
    isShowLaureates?: boolean 
    isShowSchools?: boolean 
    isShowTracks?: boolean 
    isDiary?: boolean 
    isChangeCardType?: boolean 
    maxRating?: number
    isRegisterUser?: boolean 
    logotype?: URL
    icon?: URL
    defaultProjectThumbnail?: URL
    defaultThumbnail?: URL
    defaultThumbnailName?: string
    defaultProjectThumbnailName?: string
    logotypeName?: string
    refreshRate?: string 
    maxMemberId? : number
    status?: FEST_ADMIN_STATUSES
    memberPerPage? : number 
    horoRaiting? :boolean
    memberCardHeight?: number
    memberCardType?: CARD_TYPE
    ratingType? : FESTIVAL_RATING_TYPES
    displayProjectResult?: DISLAY_PROJECT_RESULT
    startDate?: string
    finishDate? : string
    maxGanres?: number
    availableRoles?  : Role[]      
    roles?           : Role[]      
    enabled_rules? : boolean
    rating?: IRating[]                // array of rating objects
    countOfResults? : number
    reglamentTitle?: string
    reglament?: HTML           
    description? : HTML        
    clientUrl?: URL
    link1?: URL
    link2?: URL
    vk?: URL
    vk2?: URL
    email?: Email
    email2: Email 
    telegramm?: string
    telegramm2?: string
    phone?: string
    phone2: string
    newProjectEmail?: string
    newProjectMessage?: string
    memberCount? : number
    extendedFields?: IFormField[]
    vk_client_id?: string
    vk_client_secret? : string
    domain_content? : string
    version?: number
    projectMembersCount?: number
    color?: Color
    archived?:boolean
}
export interface ILand extends IEvent {
    id: ID
    domain: string
    domain_type?: string
    name: string
    icon: URL  
    event_types: string[]
    defaultThumbnail?: URL
    defaultThumbnailName?: string
    status?: any
    geo? : number[][]
    domain_content: HTML
    domain_description: HTML
    description: HTML
    owner_user: IUser
    demiurg_id?: ID
    finishDate? : string
    startDate?: string
    isPattern?: boolean
    color?: Color
    hidden?:boolean
    archived?:boolean
    newProjectEmail?: string
    newProjectMessage?: string
    viewRoles?: Role[]
    land_group_id?: ID
}
export interface IProject extends IPost {
    id: ID
    count: number
    parent: IProject
    order: number
    version: number
    nomination: HTML
    prize: HTML
    raiting: string         // canceled
    r2: number              // canceled
    actualRating: string
    correctMemberRate : ICorrectRating
    category : ICategory  
    honeycombs: IHoneycombs  
    track: ITrack
    destrict: IDestrict
    fmru_school: IPEFestSchool[]
    fmru_destrict: IDestrict[] 
    fmru_group_player: IGanre[]
    tutor: IUser
    leader: IUser
    member0: IUser
    member1: IUser
    member2: IUser
    member3: IUser
    member4: IUser
    member5: IUser
    member6: IUser
    member7: IUser
    member8: IUser
    member9: IUser
    comments: any[]            // IComment[]
    status : POST_STATUS
    form_fields: any[]
    experts: IUser[]
    ratings: IRating[]
    exhortations: IExhortation[]
    is_verified?: boolean
    verified_reason? : string
    milestone?: IProjectMilestone[]
}
export const DEFAULT_SCHOOL_THUMBNAIL = "/assets/img/fest/thumbnails/default_school.png"
export const DEFAULT_HONEYCOMBS_THUMBNAIL = "/assets/img/fest/thumbnails/default_honeycombs.png"
export const DEFAULT_GANRE_THUMBNAIL = "/assets/img/fest/thumbnails/default_ganre.jpg"
export interface IExhortation {
    id: ID
    memberId: ID
    author: IUser
    descr: string
    unixtime: Date
}
export interface ITrack extends IPost {
    id: ID
    order: number
    moderator: IUser
    moderator2: IUser 
    illustration: URL
    illustration_id: ID
    illustration_name: string
    is_closed: boolean 
    video_id: string
    count: number
    content: string
    video_src: string
    vk_url: string
    last_ad?: IAds
}
export interface ITrackAttachment {
    ID:ID
    track_id: ID
    data: Json
    type: string
    descr: string
    unixtime: number
}
export interface IDestrict extends IPost {
    id: ID
    order: number
    illustration: URL
    illustration_id: ID
    illustration_name: string
    is_closed: boolean 
    video_id: string
    count: number
    content: string
}
export interface IPEFestSchool extends IPost { 
    order: number 
    count: number
    content: string
    guardian: IUser
    guardian2: IUser
    destrict: IDestrict[]
}
export interface IHoneycombs extends IPost {
    order: number
    count: number
    thumbnail: string
}
export interface IGanre extends IPost {
    order: number
    count: number
    color: Color
    thumbnail: string
}
export interface IMilestone extends IPost {
    order: number
    count: number 
    thumbnail: string
    is_attachments_required: boolean
    is_auto_rait: boolean
    roles: Role[]
}
export interface ICategory extends IPost {
    order: number
    count: number
    max_rating: number
    color: Color
    rating_type: RATING_TYPES
    display_rating_type: DISLAY_PROJECT_RESULT
}
export interface ICritery extends IPost {
    order: number
    count: number
    max_rating: number
    category: ICategory
    milestone:IMilestone
    expert: IUser

}
export interface IDiary extends IPost {
    order: number
    project: IProject 
}
export interface IAttachments {
    data: string
    ID: ID
    type: SCALAR_TYPES
    descr: string
    unixtime: string | number
    milestone_id?: ID
    member_id?: ID
    landId?: ID
}
export interface IRating {
    id: ID
    criteryId: ID
    memberId: ID
    description: string
    display_name: string
    expertId: ID
    ID?: ID
    rating: number
    unixtime?: number
}
export enum PEFestClearPrescription {
    CLEAR  = "CLEAR",
    IGNORE = "IGNORE",
}
export interface IProjectMilestone {
    id: ID
    milestone: IMilestone
    attachments: IAttachments[]
    descriptions: string[] | null
    ratings: IRating[]
    averageRating: string
    experts: IUser[]
}
export interface IFormField {
    id: ID
    n: number
    type: SCALAR_TYPES
    value: any[]
    count: number
    name: string
    description: HTML
    require: boolean
    values: string
    yandex_form_field: any
    hide_in_page: boolean
    hide_in_card: boolean
    hide_in_projects: boolean
    hide_for_alien: boolean
    highlighted?: boolean
    add_to_rating?: boolean

} 
export enum FEST_FILTER_LABEL {
    tracks      = "tracks",
    honeycombs  = "honeycombs",
    ganres      = "ganres",
    destrict    = "destrict",
    schools     = "schools",
}
export type IFestFilters = Record<FEST_FILTER_LABEL, ID[]>
 
export interface IFestAdminProps {
    route?: string 
    title?: string
    routeObj?: IMenuItem
}
export interface IDataTableProps { 
    path: string
    data_type: string
    title:string
}
export interface IProjectListProps {
    route? : IMenuItem
    festId?: ID
    parentMode?: boolean
}
export interface ISettingsPageFormProps {
    route?: string
    title?: string
    id?: ID
}
export interface ICorrectRating {
    rate: number
    description: string
    user: ID
    display_name: string
    unixtaime: string
}
export interface ICardProjectProps {
    type: CARD_TYPE
    item: IProject
    id?: ID
    order?: number
    title?: string
    route?: string
    track?: any              // Track object
    prize?: HTML
    nomination?: HTML
    correct_member_rate?: number
    r2?: number
    isMine?: boolean
    isMineHoneycoms?: boolean
    i: number
} 
export interface IFestForm {
    title: string
    domain: string
    pattern: ILand 
    startDate: string
    finishDate: string
}
export enum FEST_ADMIN_STATUSES {
    BEFORE_START_PHASE  = 0, 
    PREPAIRE_PHASE      = 1, 
    PRESENTATION_PHASE  = 2, 
    AFTER_FINISH_PHASE  = 3
}
export enum RATING_TYPES {
    INDEPENDENT_GRADE       = "INDEPENDENT_GRADE",
    DISTRIBUTION_PROCENTAGE = "DISTRIBUTION_PROCENTAGE",
    PROCENTAGE              = "PROCENTAGE" 
}
export enum FESTIVAL_RATING_TYPES {
    INDEPENDENT_GRADE       = RATING_TYPES.INDEPENDENT_GRADE, 
    PROCENTAGE              = RATING_TYPES.PROCENTAGE,
    EACH_CATEGORY           = "EACH_CATEGORY"
}
export enum DISLAY_PROJECT_RESULT {
    AVERAGE="AVERAGE_PROJECT_RATING",
    SUMMAE="SUMMAE",
    SUMMAE_MILESTONES="SUMMAE_MILESTONES"
}
export enum CARD_TYPE {
    NONE            = "",
    BOX             = "BOX",
    ONLY_LINE       = "ONLY_LINE",
    SIPLE_STROKE    = "SIPLE_STROKE",
    STROKE          = "STROKE",
    CARD            = "CARD",
    TWO_SIZE_CARD   = "TWO_SIZE_CARD"
}
export enum CARD_SORT_TYPE {
    NACS            = "NACS",
    NDECS           = "NDECS",
    ACS             = "ACS",
    DECS            = "DECS",
    RACS            = "RACS",
    RDECS           = "RDECS",
}
export const EMTY_IMAGE_NAME: string = "empty.png"
export const NEED_DESCRIPTION_COMMENT : string = __("The maximum score must be justified.")
export const DEFAULT_FILTERS: IFestFilters = {
    honeycombs  : [ -1 ],
    tracks      : [ -1 ] ,
    destrict    : [ -1 ] ,
    schools     : [ -1 ] ,
    ganres      : [ -1 ]
}

export enum ADD_EVENT_MODE {
    NOTHING,
    LAND,
    FILE,
}

export enum SPECIAL_SORT_TYPE {
    ORDER_SORT = "order",
    ALPHABET_SORT = "alphabet",
    RATING_SORT = "rating",
    NOMINATION_SORT = "nomination",
    TRACK_SORT = "track",
    SCHOOLS_SORT = "schools",
}
export enum FILTER_NAME {
    TRACK="Track",
    SCHOOL="School",
    REGION="Region",
    RATING_MAX="RatingMax"
} 
export const SORT_ORDER_ASC = "asc"
export const SORT_ORDER_DESC = "desc"