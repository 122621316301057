import { Color, Date, Email, ID, IPost, Phone, URL, VIDEO_HOSTING_TYPE } from "@/libs/interfaces/layouts"
import { IUser } from "src/settings/zustand"


export interface IPartner extends IPost {
    link?: string
    order?:number
}

export interface IPlace extends IPost {
    coordinates: number[]
    events?: IEvent[]
    url: URL
    images: URL[]
    data: any,
    type: ID[]
    placeTypes: IPlaceType[],
    onClick?: (evt:any, coords: number[], data: any) => void
}
export interface IPlaceType extends IPost {
    color: Color
    icon: string
}
export interface IEvent extends IPost { 
    places: IPlace[] 
    commands: ITeam[]
    url?: URL
    coordinates?: number[]
    logo?: string
    thumbnail?: URL
    images: URL[]
    images_names?: string[]
    email: Email[]
    phone: Phone[]
    type: ID[]
    eventTypes: IEventType[],
    start: string
    finish: string
}
export interface IEventType extends IPost {
    color: Color
    icon: string
    module?: string
}
export interface ITeam extends IPost {
    places: IPlace[]
    users: IUser[]
    core: IUser[]
    url: URL
    type: ID[]
    teamTypes: ITeamType[]
    coordinates?: number[]
}
export interface ITeamType extends IPost {
    color: Color
    icon: string
}
export interface INews extends IPost { 
    date: Date
    url: URL 
    images: URL[]
    tags: IPost[]
    address: string
}
export interface IGoods extends IPost { 
    date: Date
    url: URL 
    undertitle: string
    producer?: string
    images: URL[]
    tags: IPost[]
    address?: string
    price?: number
}
export interface IVideo extends IPost { 
    link: URL 
    type: VIDEO_HOSTING_TYPE
    date?: Date
    images?: URL[]
    tags?: IPost[]
}
export interface IAds extends IPost { 
    date: Date
    url: URL 
    images: URL[]
    priority: number
    order?: number
    parent?: IPost,
    parent_id?: ID
    parent_type?: string
}

export enum MAP_FILTER {
    PLACES = "PLACES",
    EVENTS = "EVENTS",
    TEAM = "TEAM",
}

export type All = IEvent | IPlace | ITeam
 

export enum USER_EVENT_REQUEST_STATUS {
    NONE="NONE",
    WAITING="WAITING",
    AGREED="AGREED"
}

export type IEventRouteData = {
    path: string,
    elementName: string
}
