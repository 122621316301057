import { ID } from "@/libs/interfaces/layouts"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { DEFAULT_HONEYCOMBS_THUMBNAIL, IHoneycombs } from "@/modules/pe-fest-module/data/interfaces"
import { Collapse } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { trimContent } from "../../about/FestTrackCard"

/* Protopia Ecosystem component */
export interface IHoneyombsTaxProps {
    filter: ID[]
}
const HoneyombsTax = (props: IHoneyombsTaxProps): JSX.Element => {

    const [current, setCurrent] = useState<IHoneycombs | null>( null )
    const PEFestHoneycombs: IHoneycombs = useDataStore((state: any) => state.PEFestHoneycombs )
    useEffect(() => {
        if( props.filter[0] === -1 ) {
            setCurrent( null )
        }
        else {
            setCurrent( 
                PEFestHoneycombs?.filter((school: IHoneycombs) => school.id === props.filter[0]?.toString() )[0] || {}
            )
        }
    }, [ props.filter, PEFestHoneycombs ])

    return <> 
        <Collapse 
            className=' container-float' 
            isOpen={ !!current && !!props.filter[0] } 
            transitionDuration={ 1000 } 
        >
            <CurrentHoneyombsTax item={current} />
        </Collapse>    
    </>
}
export default HoneyombsTax

interface ICurrentHoneyombsTaxProps {
    item: IHoneycombs | null
 }
 const CurrentHoneyombsTax = ({item, ...props}: ICurrentHoneyombsTaxProps): JSX.Element => {
    const[isOpen, setOpen] = useState(false)
    const trimmedContent = trimContent( item?.content || "", 30 ) 

    return <div className='w-100'> 
        <div className=" light-colored  m-0 flex-row">
            <div className="container d-flex flex-md-row flex-column">
                <div 
                    className="w-md-33 flex-grow-100 position-relative overflow-hidden pointer "
                    style={{
                        backgroundImage:`url(${ item?.thumbnail ? item?.thumbnail : DEFAULT_HONEYCOMBS_THUMBNAIL })`, 
                        minHeight: 250,
                        backgroundSize: 'cover',
                        backgroundPosition: "center"
                    }}
                    onClick={() => setOpen( !isOpen )}
                >  
                </div>
                <div
                    className="w-md-66 flex-grow-100 p-0 " 
                >
                    <div className="lead p-3 align-items-center "> 
                        { item?.title }
                        {/* <TrackEditor item={track} /> */}
                    </div>
                    <div onClick={() => setOpen( !isOpen )} className="pointer">
                    {
                        trimmedContent.isTrim 
                            ?
                            <>
                                <Collapse isOpen={!isOpen} className={` ${ !isOpen ? "cclosed" : "" } `}>
                                <div className="px-4 pt-2 pb-2 flex-centered flex-column content-collapse">
                                    <div className="h-100 text-clip--transparent" dangerouslySetInnerHTML={{ __html: trimmedContent.content }} />
                                    
                                </div>                                               
                                </Collapse>
                                <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                                <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                                    <div 
                                    className="h-100" 
                                    dangerouslySetInnerHTML={{ __html: item?.content || "" }} 
                                    />
                                </div>
                                </Collapse>       
                            </>
                            : 
                            <>
                                <div className="px-4 py-2">
                                    { trimmedContent.content }
                                </div> 
                            </>
                    }
                    </div>
                </div> 
            </div> 
        </div>
    </div>
} 