import { useToposStore } from "./data/store"

const init = () => {
    return new Promise<any>((resolve, reject) => {
        useToposStore.setState({ 
            eventTypeAdminData: {
                ...useToposStore.getState().eventTypeAdminData,
                Land: { }
            }
        }) 
        resolve( true )
        reject( false )
    }) 
}
export default init