import { getFeedRoute, getRoute } from "@/libs/layouts"
import { __ } from "@/libs/utilities"
import { ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { Link } from "react-router-dom"

/* Protopia Ecosystem component */
export interface ITrackAttachmentsArchiveProps {
   item:ITrack
}
const TrackAttachmentsArchive = (props: ITrackAttachmentsArchiveProps): JSX.Element => {
    return <Link className="btn btn-link small" to={`/${getRoute( getFeedRoute("PETrackAttachment") )}`}>
        <span className="small">
            {__("Matherials archive")}
        </span>
    </Link> 
}
export default TrackAttachmentsArchive