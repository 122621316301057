
import { useMemo, useState } from "react"
import chroma from 'chroma-js'
import { __ } from "@/libs/utilities"
import { useIsExpert } from "@/modules/pe-fest-module/hooks/project"
import { ICategory, ICritery, IProject, IProjectMilestone } from "@/modules/pe-fest-module/data/interfaces"
import Critery from "./Critery"
import { Collapse, Tag } from "@blueprintjs/core"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import getCategoryRating, { getCategoryExperts } from "../../../data/utils/getCategoryRating"

interface IProps {
    project: IProject
    milestone: IProjectMilestone | undefined
    item: ICategory
    criteries: ICritery[]
}

const Category = (props: IProps): JSX.Element => {
    const categories: ICategory[] = useDataStore((state:any) => state.PEFestCategory || [] )
    const category: ICategory = useMemo(() => categories.filter(c => c.id === props.item.id)[0], [ categories, props.item ] )
    const isExpert: boolean = useIsExpert(props.project, props.milestone?.milestone!)
    const [isCollapse, setIsCollapse] = useState(isExpert)
    let averageRating: string = getCategoryRating(category, props.milestone?.milestone)
    const items: JSX.Element | JSX.Element[] = props.criteries
        .filter((critery: ICritery) => props.milestone?.milestone?.id === critery.milestone?.id)
        .sort((a: ICritery, b: ICritery) => {
            return (a?.order === b?.order)
                ?
                0
                :
                a?.order > b?.order ? 1 : -1
        })
        .map((critery: ICritery) => {
            return <Critery
                item={critery}
                key={critery.id}
                project={props.project}
                milestone={props.milestone!}
            />
        })
    const onCollapseToggle = () => {
        setIsCollapse(!isCollapse)
    }
    const categoryStyle: any = {}
    if (!!category?.color && /^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(category?.color as string)) {
        const accentuated = chroma(category.color as string).get('hsl.l') < .77 ? "#FFFFFF" : "#6c757d"
        categoryStyle.backgroundColor = category.color
        categoryStyle.color = accentuated
    }
    const experts = getCategoryExperts( category, props.milestone?.milestone )
    return <div>
        <div className="row">
            <div className="col-md-7 col-sm-12 critery_cell first" style={categoryStyle}>
                <div className="fmRuTitle d-flex align-items-center">
                    <span className="small pr-2 pl-4 opacity_75">
                        {__("Category")}
                    </span>
                    <span className="font-weight-bold"> 
                        {category.title} 
                    </span> 
                </div>
                <div className="small pl-4">
                    { props.item.post_content }
                </div>
                {
                    !!experts.length && <div className="d-flex align-items-center px-4 pt-1">
                        <div className="small opacity_75 pr-2 mb-1 light-font">{__("Experts, who put raiting:")}:</div>
                        { 
                            experts.map( display_name => {
                                return <Tag className="mr-1 mb-1 px-3 text-light" round minimal key={display_name}>
                                    { display_name }
                                </Tag>
                            })
                        }
                    </div>
                }
                
            </div>
            <div className="col-md-5  col-sm-12 critery_cell d-flex" style={categoryStyle}>
                <div className="my-auto " dangerouslySetInnerHTML={{ __html: averageRating }} />
                <div
                    className={`fmRU_button ml-auto${isCollapse ? " open" : ""}`}
                    onClick={onCollapseToggle}
                >
                    <i className="fa fa-caret-right" style={{ color: "#f8f9fa" }} />
                </div>
            </div>
        </div>
        <Collapse className="owerflow-x-hidden row " isOpen={isCollapse}>
            {items}
        </Collapse>
    </div>
}

Category.propTypes = {}

export default Category

