import { ID } from "@/libs/interfaces/layouts";
import { ICategory, ICritery, IProject, IRating } from "../interfaces";
import { useFestProjects } from "../store";
import { IUser, useMainStore } from "@/settings/zustand";
import {default as UUID} from "node-uuid";
import { useDataStore } from "@/modules/pe-basic-module/data/store";

export const updateCurrentRating = ( value: number, rating: IRating ) => {
    const project: IProject = useFestProjects.getState().currentProject
    const ratings: IRating[] = project.ratings.map((r: IRating) => {
        if( r.id === rating.id ) { 
            return {...r, rating: value}
        }
        return r
    }) 
    useFestProjects.getState().setCurrentProject({
        ...project,
        ratings
    })
}

export const distributeCurrentRating = (
    value: number, 
    rating: IRating,  
    category: ICategory,
    maxRating: number
) => {
    const project: IProject     = useFestProjects.getState().currentProject
    const user:IUser            = useMainStore.getState().user
    const allCriterieIDs : ID[]   = getCategoryCriteries( category )
        .map( ( critery:ICritery ) => critery.id )
    
    // рейтинги критериев, связанных через категорию
    const myRatings = project.ratings
        .filter( ( r:IRating ) => allCriterieIDs.includes( r.criteryId ) && r.expertId === user.id )

    // сумма рейтингов всех критериев, связанных через категорию
    const summ: number = myRatings.reduce(( acc : number, current : IRating ) => {
        return acc = acc + current.rating 
      }, 0 )
    
    // console.log ( summ, maxRating, summ > maxRating  )
    if( summ > maxRating ) {
        const newRatings: IRating[] = project.ratings.map((r :IRating) => {
            if( 
                r.criteryId !== rating.criteryId &&         // если критерий не текущий
                allCriterieIDs.includes( r.criteryId  ) &&  // если критерий принадлежит нужной категории
                r.expertId === user.id                      // если вы — автор
            ) {
                const newValue: number =  ( maxRating - value ) / ( summ - value ) * r.rating 
                return {
                    ...r,
                    rating: Math.floor(newValue) 
                } 
            }
            return r
        })
        useFestProjects.setState({
            currentProject : {
                ...project,
                ratings: newRatings
            }
        })
        // console.log( newRatings.filter( ( r:IRating ) => allCriterieIDs.includes( r.criteryId ) && r.expertId === user.id ).map(r => r.rating) )
    }
}

export const addRating = ( data:IRating ): IRating => {
    const project: IProject     = { ...useFestProjects.getState().currentProject }
    project.ratings = [...(project.ratings || []), data ]
    // console.log( project.ratings )
    useFestProjects.getState().setCurrentProject( project )
    return data
}


export const getCategoryCriteries = ( category: ICategory ): ICritery[] => {
    return useDataStore.getState().PEFestCritery
        .filter( ( critery:ICritery ) => critery.category?.id === category.id )
}

export const getCatUserRatings = (category:ICategory): IRating[] => {
    const project: IProject     = { ...useFestProjects.getState().currentProject }
    const user:IUser            = useMainStore.getState().user

    // сначала проверяем, существует ли оценка. Если нет - создаем. Нет!
    categoryEmptyRatings( category )
    return project.ratings
        .filter( ( r:IRating ) => {

            return getCategoryCriteries(category)
                .map( ( critery:ICritery ) => critery.id )
                .includes( r.criteryId ) && r.expertId === user.id 
        })
}

export const verifyCreateRating = ( rating:IRating, critery:ICritery ) => {
    if(rating)  return rating
    return createEmptyRating( critery )
}

export const createEmptyRating = ( critery: ICritery, currentProject: IProject | null = null ) => {
    const project: IProject     = currentProject 
        ? 
        { ...currentProject } 
        : 
        { ...useFestProjects.getState().currentProject }
    const user:IUser            = useMainStore.getState().user
    const newRating:IRating = { 
        id:UUID.v4(), 
        description:"", 
        expertId: user.id, 
        display_name: user.display_name!, 
        rating:0,  
        criteryId: critery.id,
        memberId: project.id
    }
    return addRating( newRating )
}

export const categoryEmptyRatings = (category:ICategory) => {
    const project: IProject     = { ...useFestProjects.getState().currentProject }
    const user:IUser            = useMainStore.getState().user
    getCategoryCriteries( category )
        .forEach(critery => {
            // проверяем есть ли оценка у этого юзера в этом проекте на этот критерий
            const isInclude: boolean = !!project.ratings.filter(r =>
                {
                    return r.criteryId === critery.id && r.expertId === user.id && r.memberId === project.id
                }).length
            // если нет -- создаём куклу.
            if(!isInclude) {
                createEmptyRating(critery)
            }
        }) 
}