import { IFestival, IFormField } from "../interfaces"
import { useFestDataStore } from "../store"

export const REGISTER_PROJECT = ( data: any ) => { 
    // console.log(data.item)
    const festival:IFestival = useFestDataStore.getState().festival 
    const form_fields: string[] = ( festival.extendedFields || [] ).map( (field:IFormField, i: number) => { 
        return Array.isArray( data.item.form_fields[i] ) && !!data.item.form_fields[i].length
            ?
            '"' + data.item.form_fields[i].join('","') + '"'
            :
            ""
    }) 
    const site: string = data.land_id
        ?
        ` land_id: "${data.land_id}" `
        :
        `` 
    const fmru_group_player = !!data.item.fmru_group_player?.length
        ?
        ` fmru_group_player: [${ data.item.fmru_group_player.join(",") }] `
        :
        ""
    const gql: string =  `
        mutation register_project {
            registerPEFestProject(
                ${site} 
                input: {
					title : "${ data.item.title }"
                    thumbnail: "${data.item.thumbnail}"
                    thumbnail_name: "${data.item.thumbnailName}"
                    post_content: "${ data.item.content.split(`
`).join("</p><p>") }"
                    tutor_name: "${ data.item.tutorName }"
                    tutor_email: "${ data.item.tutor_email }"
                    ${ 
                        !!data.item.track
                            ?
                            `track: ${ data.item.track }  `
                            :
                            ''
                    }
                    ${ 
                        !!data.item.fmru_school
                            ?
                            `fmru_school: ${ data.item.fmru_school } `
                            :
                            ''
                    } 
                    ${ 
                        !!data.item.destrict
                            ?
                            `destrict: ${ data.item.destrict } `
                            :
                            ''
                    }
                    ${ fmru_group_player } 
                    form_fields: ${ '[[' + form_fields.join('],[') + ']]' },
                    user_role: "${data.item.user_role}"
                }
            ){
                message
                id
                tutor
                project {
                    id
                    title
                    post_content
                    fmru_group_player {
                        id
                        title
                    }
                    fmru_destrict {
                        id
                        title
                    }
                    fmru_school {
                        id
                        title
                    }
                    track {
                        id
                        title
                    } 
                }
            }
        }
    `
    //console.log( gql ) 
    return gql
}