import { ID } from "@/libs/interfaces/layouts"
import { Loading } from "@/libs/useful"
import switchToBlog from "@/modules/pe-basic-module/hooks/switchToBlog"
import getDefEventRoutes from "@/modules/pe-topos-module/components/getDefEventRoutes"
import { IToposStore, useToposStore } from "@/modules/pe-topos-module/data/store"
import { useMainStore } from "@/settings/zustand"
import { useEffect } from "react"
import { Outlet, Route, Routes, useParams } from "react-router"
import { IFestDataStor, useFestDataStore } from "../../data/store"
import { useFestival } from "../../hooks/festival"
import AboutForm from "../about/AboutForm"
import RateSystemForm from "../about/RateSystemForm"
import MessagesForm from "../messages/MessagesForm"
import ModerationForm from "../moderation/ModerationForm"
import ProjectForm from "../project/ProjectForm"
import ProjectListForm from "../projectList/ProjectListForm"
import { ILand } from "../../data/interfaces"
import NoMatchView from "@/modules/pe-basic-module/views/NoMatchView"
import { DEFAULT_404_PROPS } from "@/libs/interfaces/dummies"
import { __ } from "@/libs/utilities"
import AdListForm from "../AdListForm"
import TrackAttachmentListForm from "../TrackAttachmentListForm"

/* Protopia Ecosystem component */
export interface IFestivalProps {

}
const Festival = (props: IFestivalProps): JSX.Element => {
    const params = useParams()
    const festId: ID = params.landId || "-1" 
    const isLoading = useFestival( true, festId )
    const land:ILand = useFestDataStore((state:IFestDataStor) => state.site )
    const eventRoutes = useToposStore((state: IToposStore) => state.eventRoutes)
    useEffect(() => {
        useFestDataStore.setState({ festId })
        useMainStore.setState({landId: festId})
        switchToBlog( festId )
    }, [festId]) 
    
    if( isLoading ) return <div className="layout-app-state flex-center">
        <Loading />
    </div> 
    if(!land.id) {
        return <div className="layout-app-state flex-centered">
            <NoMatchView {...DEFAULT_404_PROPS} />
        </div>
    }
    return <>
        <Routes> 
            <Route element={<><Outlet /></>} >     
                <Route path="*" element={<AboutForm {...props} parentMode />} >  
                    <Route path="insert-project" element={<></>} /> 
                    <Route path="reglament" element={<></>} /> 
                </Route>   
                <Route path="rate-system" element={<RateSystemForm  {...props} parentMode />} /> 
                <Route path="members" element={<ProjectListForm  {...props} parentMode />} /> 
                <Route path="ads" element={<AdListForm  {...props} parentMode />} /> 
                <Route path="track-attachments" element={<TrackAttachmentListForm  {...props} parentMode />} /> 
                <Route path="messages" element={ <MessagesForm  parentMode /> } /> 
                <Route path="moderation" element={<ModerationForm  {...props} parentMode />} /> 
                <Route
                    path="project/:id/*"  
                    element={
                        <div className="w-100"> 
                            <ProjectForm  /> 
                        </div> 
                    }
                />
                { getDefEventRoutes( eventRoutes) } 
            </Route>
        </Routes>
        {
            land.archived && 
            <div className="mine-corner-no-verified corner-card-project pe-pennant-dark">
                {__("in archive")}
            </div>
        }            
    </>
}
export default Festival