import { __ } from "@/libs/utilities"
import { ILand, ITrack, ITrackAttachment } from "../../data/interfaces"
import { useEffect, useState } from "react"
import { actions } from "../../data/actions"
import { GET_TRACK_ATTACHMENTS } from "../../data/actionTypes" 
import { IFestDataStor, useFestDataStore } from "../../data/store"
import { Tag } from "@blueprintjs/core" 
import TrackAttachmentsArchive from "../projectList/filter-terms/TrackAttachmentsArchive"
import TrackAttacmentAdd from "../projectList/filter-terms/TrackAttacmentAdd"

/* Protopia Ecosystem component */
export interface ITrackAttachmentlabelProps {
    item: ITrack | null
}
const TrackAttachmentlabel = (props: ITrackAttachmentlabelProps): JSX.Element => {
    const land:ILand = useFestDataStore((state:IFestDataStor) => state.site ) 
    const [attachments, setAttachments] = useState<ITrackAttachment[]>([])
    useEffect(() => {
        actions(GET_TRACK_ATTACHMENTS, {land_id: land.id, track_id: props.item?.id})
            .then((resp : ITrackAttachment[]) => {
                setAttachments(resp)
            })
    }, [])
    return !!props.item 
        ?
        <div className='d-flex py-1 px-4 align-items-center'>
            <div className="width-200 min-width-200 max-width-200 d-flex">
                <div className="small mr-3 text-no-break">
                    { __( "Matherials" ) }:
                </div> 
            </div>            
            <i className="fas fa-paperclip mr-3" />
            <div className="flex-grow-100">
                { 
                    attachments
                        .filter((__, i) => i < 3 )
                        .map( a => <AttacmentTag key={a.ID} item={a} /> ) 
                }
            </div> 
            <TrackAttachmentsArchive item={ props.item! } /> 
            <TrackAttacmentAdd item={props.item!} /> 
        </div>
        :
        <></>
}
export default TrackAttachmentlabel

interface IAttachmentTagPros {
    item: ITrackAttachment
}
const AttacmentTag = ({item}: IAttachmentTagPros) : JSX.Element => { 
    
    return <Tag minimal className="pointer">
        <div className="small">
            {item.descr}
        </div>
    </Tag>
}