import { IState, IUser, useMainStore } from "@/settings/zustand";
import { ITrack } from "../data/interfaces";
import { isRole } from "@/settings/zustand/user";
import { useEffect, useState } from "react";

export function useIsTrackModerator( track: ITrack): boolean {
    const user:IUser = useMainStore((state:IState) => state.user) 
    const [value, setValue] = useState<boolean>( false )
    const getTrackModers = () => {
        // console.log( (isRole(["Track_moderator"]) ), ( track.moderator?.id === user.id || track.moderator2?.id === user.id) , track.moderator2?.id, user.id  )
        setValue( ( isRole(["Track_moderator"]) ) && ( track.moderator?.id === user.id || track.moderator2?.id === user.id)  )
    }
    useEffect(() => getTrackModers(), []) 
    return value
}