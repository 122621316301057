import React from 'react' 
import { Button, Tag } from '@blueprintjs/core'
import { ID } from 'src/libs/interfaces/layouts'
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'

const ImageCheckbox = ( props: IScalarForm ): JSX.Element => {
    return <Scalar 
    { ...props } 
    enabledForm={<ImageCheckboxEnabled {...props} />}
    desabledForm={<ImageCheckboxDesabled {...props} />}
/> 
} 

export default ImageCheckbox

export const ImageCheckboxEnabled =  ( props: IScalarForm ): JSX.Element => {
    const onChange = ( elem:any ) => { 
        // console.log( elem, props.values, props.value )
        let _value: any[] = [ ...( Array.isArray( props.value ) ? props.value : [] ) ]
        if( !!_value?.filter((v:any) => v._id === elem.id).length ) {
            _value = _value.filter((v:any) => v._id === elem.id )
        }
        else {
            _value = [ ..._value, elem] 
        }
        if( props.onChange && _value ) {
            const newValue = _value
                .map( nv => ({ id: nv._id || nv.id }) )
            //console.log( _value, newValue )
            props.onChange( newValue, props.field, "" )
        }
    }
    return <div className="d-flex flex-wrap"> 
    {
        props.values?.map((value, i) => { 
            const checked = Array.isArray( props.value )
                ?
                !!props.value.filter( v => v._id === value._id ).length 
                :
                false
            return <Button
                key={i}
                className="scalar-imgage-radio-label"
                onClick={( ) => onChange( {id: value._id, title: value.title })}
                data-v={ value._id }
                minimal={ !checked }
            >
                <img
                    src={ value.img }
                    alt={ value.title }
                    className="scalar-imgage-radio-img"
                    style={{
                        height: value.height,
                        top: 0,
                        opacity: checked
                            ? 
                            value.icon_opacity
                                ? 
                                value.icon_opacity
                                : 
                                1
                            : 
                            0.125,
                    }}
                />
                <div className="scalar-imgage-radio-title">
                    { value.title }
                </div>
                <div 
                    className="scalar-imgage-radio-description" 
                    style={{maxWidth: value.width || value.height}}
                >
                    { value.desription }
                </div> 
            </Button>
        })
    }
    </div>
}
export const ImageCheckboxDesabled =  ( props: IScalarForm ): JSX.Element | JSX.Element[] => {
    return Array.isArray(props.value)
        ?
        props.value.map( ( value, i ) => {
            return <div key={value}>
                <img
                    src={value.img}
                    alt={props.value.title}
                    className="scalar-imgage-radio-img"
                    style={{
                        height: value.height,
                        top: 0 
                    }}
                />
                <div >{ value }</div>
            </div>
        })
        :
        <Tag minimal>--</Tag>
}