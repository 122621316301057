import FieldInput from "@/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { AppToaster } from "@/libs/useful"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import { actions } from "@/modules/pe-fest-module/data/actions"
import { GET_TRACK_ATTACHMENTS, UPDATE_TRACK_ATTACHMENT } from "@/modules/pe-fest-module/data/actionTypes"
import { ILand, ITrack } from "@/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, useFestDataStore } from "@/modules/pe-fest-module/data/store"
import { useIsTrackModerator } from "@/modules/pe-fest-module/hooks/track"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface ITrackAttacmentAddProps {
    item: ITrack
}
const TrackAttacmentAdd = ({item}: ITrackAttacmentAddProps): JSX.Element => {
    const land:ILand = useFestDataStore((state:IFestDataStor) => state.site ) 
    const isTrackModerator  = useIsTrackModerator( item )
    const [descr, setDescr] = useState<string>("")
    const [type, setType]   = useState<SCALAR_TYPES>(SCALAR_TYPES.STRING)
    const [data, setData]   = useState<any>( "" )

    const onAdd = () => {
        const actionData = {
            input: {
                track_id: item.id,
                data,
                type,
                descr
            },
            land_id: land.id
        }
        //console.log(actionData)
        actions(UPDATE_TRACK_ATTACHMENT, actionData)
            .then(resp => {
                AppToaster.show({
                    message: __("Attachment created.")
                })
                actions(GET_TRACK_ATTACHMENTS, {land_id: land.id, track_id: item.id})
            })
    }
    const onType = (value: SCALAR_TYPES) => {
        setType(value)
        setData("")
    }
    return isTrackModerator
        ?
        <div className='pe-trackattacmentadd-container'>
            <ConfirmButton
                    buttonIcon="plus"
                    buttonMinimal
                    buttonIntent="danger"
                    onConfirm={ onAdd }
                    yesLabel={ __("Add Matherial") }
                    noLabel={  __("Close") }
                    dialogTitle={__("Add new Matherial")}
            >
                <div className="p-4">
                    <FieldInput
                        title={ __("Label") }
                        editable
                        value={ descr }
                        onChange={ setDescr }
                    />
                    <FieldInput
                        title={ __("Type") }
                        editable 
                        value={ type }
                        type={ SCALAR_TYPES.TAGS }
                        values={
                            [ SCALAR_TYPES.UPLOAD, SCALAR_TYPES.YOUTUBE_ID, SCALAR_TYPES.URL, SCALAR_TYPES.MEDIA, SCALAR_TYPES.STRING ].map(s => ({
                                _id:s,
                                title: __( s.toString() )
                            }))
                        }
                        onChange={ onType }
                    />
                    <div className="py-3">
                        <FieldInput
                            title={ __(  type.toString() ) }
                            editable
                            type={ type }
                            value={ data }
                            onChange={ setData }
                        />
                    </div>
                    
                </div>
            </ConfirmButton>
        </div>
        :
        <></>
}
export default TrackAttacmentAdd