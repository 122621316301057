import { Popover } from "@blueprintjs/core"
import { ru } from "date-fns/locale"
import { useEffect, useState } from "react"
import { DayPicker, SelectSingleEventHandler } from "react-day-picker"
import Moment from "react-moment"
import { useParams } from "react-router"
import { __ } from "src/libs/utilities"
import actions from "src/modules/pe-basic-module/data/actions"
import { ILand } from "src/modules/pe-fest-module/data/interfaces"
import { IFestDataStor, useFestDataStore } from "src/modules/pe-fest-module/data/store"
import { useFestival } from "src/modules/pe-fest-module/hooks/festival"
import { UPDATE_SINGLE_TYPE } from "src/modules/pe-basic-module/data/actionTypes"

/* Protopia Ecosystem component */
export interface IDateSelectProps {
    field: keyof ILand
}
const css = `
    .my-selected:not([disabled]) { 
        font-weight: bold; 
        border: 2px solid currentColor;
        border-radius: 3px;
    }
    .my-selected:hover:not([disabled]) { 
        border-color: blue;
        color: blue;
        border-radius: 3px;
    }
    .my-today { 
        font-weight: bold; 
        color: red;
    }
`;
const DateSelect = ({field, ...props}: IDateSelectProps): JSX.Element => {
    const params = useParams()
    const festId = params.landId || ""
    const isLoading = useFestival(true, festId) 
    const land: ILand = useFestDataStore((state:IFestDataStor) => state.site)
    const [value, setValue] = useState<Date>( )  

    useEffect(() => {
        setValue( new Date( land[ field ] * 1000 ) )
    }, [ land ])

    const onChange = (date: Date, date2: Date, date3: any) => { 
        console.log( date, date2,date3 )
        setValue( date ) 
        actions(
            UPDATE_SINGLE_TYPE, 
            {
                data_type: "Land",
                id: festId, 
                item: {
                    [field] : Math.floor( date.getTime() / 1000 )
                }
            }
        ).then(res => {
            console.log( res )  
            console.log( new Date( res[ field ] * 1000 ) )  
            setValue( new Date( res[ field ] * 1000 ) )
        }) 
    }
    return <>
        <style>{css}</style>
        <Popover
            content={<div className="p-4"> 
                <div className="lead text-center">
                    {__( field.toString() )}
                </div>
                <DayPicker
                    mode="single"
                    selected={ value}
                    numberOfMonths={1} 
                    onSelect={ onChange as SelectSingleEventHandler}
                    locale={ ru }
                    showOutsideDays
                    fixedWeeks 
                    modifiersClassNames={{
                        selected: 'my-selected',
                        today: 'my-today'
                    }}
                    modifiersStyles={{
                        disabled: { fontSize: '75%' }
                    }}
                />
            </div>}
        >
            <div className='btn btn-sm  text-force-contrast mr-1 mb-1 btn-outline-secondary'>
                {
                    land[field]
                        ?
                        <Moment locale="ru" format="D MMMM YYYY">
                            { value }
                        </Moment>
                        :
                        __( field.toString() )
                }
            </div>
        </Popover>
    </>
}
export default DateSelect