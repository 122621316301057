import { ID } from "@/libs/interfaces/layouts"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { DEFAULT_GANRE_THUMBNAIL, IGanre } from "@/modules/pe-fest-module/data/interfaces"
import { Collapse } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { trimContent } from "../../about/FestTrackCard"
import { LayoutIcon } from "@/libs/useful"

/* Protopia Ecosystem component */
export interface IGanreTaxProps {
    filter: ID[]
}
const GanreTax = (props: IGanreTaxProps): JSX.Element => { 
    const [current, setCurrent] = useState<IGanre | null>( null )
    const PEFestGanre: IGanre = useDataStore((state: any) => state.PEFestGanre || [] )
    useEffect(() => {
        if( !props.filter[1] ) {
            setCurrent( null )
        }
        else {
            setCurrent( 
                PEFestGanre?.filter((ganre: IGanre) => ganre.id === props.filter[1]?.toString() )[0] || {}
            )
        }
    }, [ props.filter, PEFestGanre ])

    return <> 
        <Collapse 
            className=' container-float' 
            isOpen={ !!current && !!props.filter[1] } 
            transitionDuration={ 1000 } 
        >
            <CurrentHoneyombsTax item={current} />
        </Collapse>    
    </>
}
export default GanreTax

interface ICurrentHoneyombsTaxProps {
    item: IGanre | null
 }
 const CurrentHoneyombsTax = ({item, ...props}: ICurrentHoneyombsTaxProps): JSX.Element => {
    const[isOpen, setOpen] = useState(false)
    const trimmedContent = trimContent( item?.post_content || "", 30 ) 

    console.log( item )

    return <div className='w-100'> 
        <div className=" light-colored  m-0 flex-row">
            <div className="container d-flex flex-md-row flex-column">
                <div 
                    className="w-md-33 flex-grow-100 position-relative overflow-hidden pointer flex-centered"
                    style={{
                        // backgroundImage:`url(${ item?.thumbnail ? item?.thumbnail : DEFAULT_GANRE_THUMBNAIL })`, 
                        minHeight: 250,
                        backgroundSize: 'cover',
                        backgroundPosition: "center",
                        backgroundColor: item?.color?.toString()
                    }}
                    onClick={() => setOpen( !isOpen )}
                >  
                    <LayoutIcon
                        src={ item?.thumbnail || "" } 
                        style={{
                            width: 100,
                            height: 100
                        }}
                    />
                </div>
                <div
                    className="w-md-66 flex-grow-100 p-0 " 
                >
                    <div className="lead p-3 align-items-center "> 
                        { item?.title }
                        {/* <TrackEditor item={track} /> */}
                    </div>
                    <div onClick={() => setOpen( !isOpen )} className="pointer">
                    {
                        trimmedContent.isTrim 
                            ?
                            <>
                                <Collapse isOpen={!isOpen} className={` ${ !isOpen ? "cclosed" : "" } `}>
                                <div className="px-4 pt-2 pb-2 flex-centered flex-column content-collapse">
                                    <div className="h-100 text-clip--transparent" dangerouslySetInnerHTML={{ __html: trimmedContent.content }} />
                                    
                                </div>                                               
                                </Collapse>
                                <Collapse isOpen={isOpen} className={` ${ isOpen ? "cclosed" : "" } `}> 
                                <div className="px-4 pt-4 pb-5 flex-centered flex-column content-collapse">
                                    <div 
                                    className="h-100" 
                                    dangerouslySetInnerHTML={{ __html: item?.post_content || "" }} 
                                    />
                                </div>
                                </Collapse>       
                            </>
                            : 
                            <>
                                <div className="px-4 py-2">
                                    { trimmedContent.content }
                                </div> 
                            </>
                    }
                    </div>
                </div> 
            </div> 
        </div>
    </div>
}
