import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { LayoutIcon } from "@/libs/useful"
import { __, getContrastColor } from "@/libs/utilities"
import { ILand } from "@/modules/pe-fest-module/data/interfaces"
import { useFestDataStore } from "@/modules/pe-fest-module/data/store"
import { eventTypes } from "@/modules/pe-topos-module/data/mocks/eventTypes"
import { Button, Intent } from "@blueprintjs/core"
import { useState } from "react"

const sz = 13

/* Protopia Ecosystem component */
export interface ICopyMasterProps {
    onStartCopy : (data: ILand) => void, 
}
const getDate = (): string => {
    let date = new Date();
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date) 
    let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date) 
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date) 
    return `${day}-${month}-${year}`.toLowerCase()
}
const CopyMaster = (props: ICopyMasterProps): JSX.Element => { 
    const[className, setClassName] = useState<string>("")
    const [site, setSite] = useState<ILand>({
        ...useFestDataStore.getState().site,
        name:   `${ useFestDataStore.getState().site.name   }-1`,
        domain: `festival-${ getDate() }`,
    })
    
    const changeField = ( value: any, field: string ) => {
        setSite({...site, [field]: value})
    }
    const onEventTypes = (value: string) => { 
        let event_types = [ ...(site?.event_types || []) ]
        if( !!event_types?.filter(e => e === value).length ){
            event_types = event_types?.filter(e => e !== value)
        }
        else {
            event_types.push(value)
        }
        console.log( event_types )
        setSite({
            ...site, 
            event_types 
        })
    }
    const doStart = () => {
        setClassName( 'untouchble opacity_75' )
        let _site:any = {
            title: site.name,
            domain: site.domain,
            source: site.id,
            event_types: site.event_types
        } 

        props.onStartCopy( _site )
    }

    return <div className={className}>
        <div className='display-6 mb-3'>
            {__("New festival data")}
        </div>
        <div className="lead mb-1">{__("Set event type")}</div>
        <div className="d-flex flex-wrap">
        {
            eventTypes().map((et, i) => {
                const enbld: boolean = !!site.event_types?.filter(e => e === et.title).length
                return <div className="d-flex mt-0" key={et.id}>
                    <div 
                        className=" m-1 d-flex align-items-center rounded-pill pointer"  
                        key={ et.id } 
                        style={{ 
                            background: enbld ? et.color : "#777777", 
                            height: sz + 18,
                            opacity: enbld ? 1 : 0.75
                        }}
                        onClick={ () => onEventTypes( et.title ) }
                    >
                        <span className="topos-placetype-label-icon" style={{ height: sz + 18, width:sz + 18 }}>
                            <LayoutIcon
                                src={ et.icon }
                                className=""
                                style={{ width:sz, height: sz }}
                            />
                        </span>
                        <span 
                            className="px-3 py-1" 
                            style={{ 
                                color: enbld ? getContrastColor(et.color): "#FFFFFF" 
                            }}
                        >
                            { __( et.title ) }
                        </span>
                    </div>
                </div>
            })
        }            
        </div>

        <FieldInput 
            title={__("Title")} 
            type={SCALAR_TYPES.STRING}
            ID="name"
            id="name" 
            value={ site.name } 
            editable
            onChange={value => changeField(value, "name")}
        />
        <FieldInput 
            title={__("Uniq domain name")} 
            type={SCALAR_TYPES.STRING}
            ID="domain"
            id="domain"
            commentary={__("only lat string, may use '-' symbol")}
            value={ site.domain } 
            editable
            onChange={value => changeField(value, "domain")}
        /> 
        <FieldInput 
            title={__("Short description")} 
            type={SCALAR_TYPES.STRING}
            ID="description"
            id="description" 
            value={ site.description } 
            max={70}
            editable
            onChange={value => changeField(value, "description")}
        /> 
        <div className='mt-4'>
            <Button
                intent={Intent.DANGER}
                className="lead p-4"
                fill
                onClick={ doStart }
            >
                {__("Start copy just now")}
            </Button> 
        </div>
    </div>
}
export default CopyMaster