import { IFestDataStor, IFestProjectsProps, useFestDataStore, useFestProjects } from "@/modules/pe-fest-module/data/store"
import { ICriteryRatingProps } from "./CriteryRatingView"
import { FESTIVAL_RATING_TYPES, ICategory, ICritery, IFestival, IProject, IRating, RATING_TYPES } from "@/modules/pe-fest-module/data/interfaces"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { Callout, Intent } from "@blueprintjs/core"
import { __ } from "@/libs/utilities"
import ExpertCheckList from "./ExpertCheckList"
import ExpertSlider from "./ExpertSlider"
import ExpertUnitedSlider from "./ExpertUnitedSlider"
import { useEffect, useState } from "react"
import { createEmptyRating } from "@/modules/pe-fest-module/data/utils/updateCurrentRating"

interface IProps extends ICriteryRatingProps {
    critery: ICritery
    category: ICategory | null 
    onOpen: (isOpen: boolean, isMax: boolean, data: any) => void
    onCheck: (data: any) => void
}

const CriteryRatingEngine = (props: IProps): JSX.Element => {
    const user: IUser = useMainStore((state: IState) => state.user)
    const project :IProject = useFestProjects(( state:IFestProjectsProps ) => state.currentProject)
    const getRating = (project: IProject): IRating => {
        const ratings: IRating[] = project?.ratings?.filter((rating: IRating) => rating.criteryId === props.critery.id)
        let r:IRating =  ratings?.filter((rating: IRating) => {
            return rating.criteryId === props.critery.id && rating.expertId === user.id
        })[0]
        if(!r) {
            return createEmptyRating( props.critery )
        }
        return r
    }
    const [rating, setRating] = useState<IRating>( getRating( project ) )
    useEffect(() => useFestProjects.subscribe(
        ( state: IFestProjectsProps ) => {
            const newRating:IRating = getRating(state.currentProject)
            // console.log( newRating )
            setRating( newRating )
        }
    ), []) 
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const maxRating: number = festival.isIndependentGradeCritery ? props.critery.max_rating : festival.maxRating || 5

    switch (festival.ratingType) {
        case FESTIVAL_RATING_TYPES.PROCENTAGE: 
            return <ExpertSlider
                critery={props.critery}
                rating={rating}
                maxRating={maxRating}
            />
        case FESTIVAL_RATING_TYPES.EACH_CATEGORY: 
            switch (props.category?.rating_type) {
                case RATING_TYPES.DISTRIBUTION_PROCENTAGE: 
                    return <ExpertUnitedSlider
                        critery={props.critery}
                        rating={rating}
                        maxRating={maxRating}
                    />
                case RATING_TYPES.PROCENTAGE: 
                    return <ExpertSlider
                        critery={props.critery}
                        rating={rating}
                        maxRating={maxRating}
                    />
                case RATING_TYPES.INDEPENDENT_GRADE:
                default: 
                    return <ExpertCheckList
                        critery={props.critery}
                        rating={rating}
                        maxRating={maxRating}
                    />
            }
        case FESTIVAL_RATING_TYPES.INDEPENDENT_GRADE:
        default: 
            return <ExpertCheckList
                critery={props.critery}
                rating={rating}
                maxRating={maxRating}
            />

    }
}

export default CriteryRatingEngine