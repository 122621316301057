import { SCALAR_TYPES } from "@/libs/scalars"
import { IFestival } from "../../data/interfaces"
import { useFestDataStore } from "../../data/store"
import { URL_SEPARATOR } from "@/libs/scalars/scalars/URL"

/* Protopia Ecosystem component */
export interface IFormFieldRowProps {
   singleFormField: any
   i: number 
}
const FormFieldRow = ({singleFormField, i}: IFormFieldRowProps): JSX.Element | JSX.Element[] => {
    return Array.isArray( singleFormField )
        ?
        singleFormField?.map( (field: any, ii: number) => {
            return <div 
                className="pe-table-cell" 
                key={i +"_" + field.toString() + Math.random() }
                title={ field.toString() }
            > 
                <SingleField field={ field } i={ i } />
            </div> 
        })
        :
        <></>
}
export default FormFieldRow

interface ISingleFieldProps {
    field: any
    i: number
}
const SingleField = ({field, i}:ISingleFieldProps) : JSX.Element | any => {
    const festival: IFestival = useFestDataStore.getState().festival
    if( !!festival.extendedFields ) {
        switch(festival.extendedFields[i].type) {
            case SCALAR_TYPES.URL:
                const url = field.split( URL_SEPARATOR )
                return !!field
                    ? 
                    <div className="d-flex small">
                        <div className="bg-warning-light text-force-black px-2 pe-table-cell">
                            { url[ 0 ] }
                        </div>
                        <div className="bg-secondary-dark text-light px-2 pe-table-cell">
                            { url[ 1 ] }
                        </div>
                    </div>  
                    :
                    <></>
            default:
                return field.toString()
        }
    } 
    return <></>
}