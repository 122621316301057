import { Button, Dialog, Icon, Intent } from "@blueprintjs/core"
import { ITrack } from "../../data/interfaces"
import { __ } from "@/libs/utilities"
import { useEffect, useState } from "react"
import { getVideoValue } from "@/libs/scalars/scalars/Youtube"
import { VIDEO_HOSTING_TYPE, WINDOW_WIDTH } from "@/libs/interfaces/layouts"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"

/* Protopia Ecosystem component */
export interface ITrackVideoProps {
   item:ITrack
}
const TrackVideo = (props: ITrackVideoProps): JSX.Element => {
    const [isVideoOpen, setVideoOpen] = useState(false)
    const [videoId, ] = useState<string>( getVideoValue( props.item.video_id )[0] )
    const [hostingType, ]= useState<VIDEO_HOSTING_TYPE>( getVideoValue( props.item.video_id )[1] as VIDEO_HOSTING_TYPE || VIDEO_HOSTING_TYPE.YOUTUBE )
    
    const playVideo = (evt: any) => {
        evt.stopPropagation()
        evt.preventDefault()
        setVideoOpen(true)
      }
      const stopVideo = (evt: any) => {
        evt.stopPropagation()
        evt.preventDefault()
        setVideoOpen(false)
      } 
    
    return videoId 
        ?
        <> 
            <div className='pe-track-video soc codepen position-absolute right' onClick={playVideo}>
                <i className="fas fa-play" />
            </div>
            <Dialog
                isOpen={ isVideoOpen }
                onClose={ stopVideo }
                className="over-z-index"
            >
                <div className='position-relative'> 
                    <Video title={props.item.title} videoId={videoId} hostingType={hostingType} />                    
                    <Button className='position-absolute top right px-3 py-2' onClick={stopVideo} intent={Intent.DANGER}>
                        <Icon size={33} color="#FFFFFF" icon="cross" />
                    </Button>
                </div>
                
            </Dialog>
        </>
        :
        <></>
}
export default TrackVideo


const Video = ({title, videoId, hostingType}:any) : JSX.Element => {
    const {width, height} = useScreenSize()
    const getSize = () => {
        return width < WINDOW_WIDTH.TABLET 
            ?
            [ width, height ]
            :
            [ 800, 460 ]
    }
    const [videoWidth, setvideoWidth]   = useState(getSize()[0])
    const [videoHeight, setvideoHeight] = useState(getSize()[1])
    useEffect(() => {
        setvideoWidth(  getSize()[0] )
        setvideoHeight( getSize()[1] )
    }, [ width ])
    switch(hostingType) {
        case VIDEO_HOSTING_TYPE.VK:
        case VIDEO_HOSTING_TYPE.RUTUBE:
            return <>
                <div dangerouslySetInnerHTML={{__html: videoId.replace(/width='\d*'/, "width='"+ videoWidth + "'").replace(/height='\d*'/, "height='"+videoHeight+"'")}} />
            </>
        default:
            return <iframe 
                title={ title }
                width={videoWidth} 
                height={videoHeight} 
                src={`https://www.youtube.com/embed/${ videoId }?autoplay=1`} 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowFullScreen
            />
    }
}